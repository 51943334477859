import React from "react";
import { Icon } from "@iconify/react";

function DeleteModal({
  setIsDeleteModal,
  isDeleteModal,
  chatIndexToDelete,
  onDelete,
}) {
  const closeModal = () => {
    setIsDeleteModal(false);
  };

  return (
    <>
      {isDeleteModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="rounded-lg w-[350px] md:w-[600px] bg-white">
            <div className="p-4 md:p-4 flex items-center justify-between">
              <p className="text-color text-2xl font-medium text-center">
                Delete Chat
              </p>
              <button
                onClick={closeModal}
                className="flex items-center p-2  text-secondary rounded-lg font-normal text-lg hover:bg-gray-100 group"
              >
                <Icon icon="iconamoon:close-light" className="text-[25px]" />
              </button>
            </div>
            <hr className="h-px w-full md:w-[37rem] m-auto bg-borderColor border-0"></hr>
            <div className="p-4 md:p-4 ">
              <p>Are you sure you want to delete this chat?</p>
            </div>

            <div className="flex items-center md:px-2 pb-3 pt-3 float-right	">
              <button
                onClick={closeModal}
                className="flex items-center px-4  py-2 text-[#13343B] rounded-md font-normal text-lg  hover:text-gray-400 bg-gray-100 group"
              >
                Cancel
              </button>
              <button
                onClick={() => onDelete(chatIndexToDelete)}
                className="flex items-center font-sans px-4 ml-4 mr-2 py-2 text-white bg-red-900 rounded-md font-normal text-lg  hover:bg-red-500 group"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DeleteModal;

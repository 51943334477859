import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import apiClient from "../utils/axiosInstance";
import { toast } from "react-toastify";

export const getModelDetails = createAsyncThunk("getModelDetails", async () => {
    try {
        const response = await apiClient().get(`/model-details`);
        return response.data;
    } catch (error) {
        toast.error(`${error}`);
        throw error;
    }
});

export const aiModels = createSlice({
    name: "aiModels",
    initialState: {
        aiModels: {},
        status: null,
    },
    reducers: {
        changeModel: (state, action) => {
            const { providerId, models } = action.payload;
            state.aiModels.models[providerId] = models;
        },
        addNewModel: (state, action) => {
            const { providerId, models } = action.payload;
            state.aiModels.models[providerId] = models;
        },
        removeModel: (state, action) => {
            const { providerId, modelName } = action.payload; 
            if (state.aiModels.models[providerId]) {
                state.aiModels.models[providerId] = state.aiModels.models[providerId].filter(model => model.model !== modelName);
            }
        },
        updateModel: (state, action) => {
            const { providerId, oldModelName, newModelName } = action.payload; 
        
            if (state.aiModels.models[providerId]) {
                const modelIndex = state.aiModels.models[providerId].findIndex(model => model.model === oldModelName);
                if (modelIndex !== -1) {
                    state.aiModels.models[providerId][modelIndex].model = newModelName;
                }
            }
        },
        
        addNewProvider: (state, action) => {
            const { models, provider } = action.payload;
            state.aiModels.providers.push(action.payload.provider);

            if (action.payload?.models) {
                if (!state.aiModels.models[provider.modelId]) {
                    state.aiModels.models[provider.modelId] = [];
                }

                state.aiModels.models[provider.modelId].push(models[provider.modelId]);
            }

        },
        addNewProviderKey: (state, action) => {
            const { providerId, newProviderKey } = action.payload;
            const providerIndex = state.aiModels.providers.findIndex(provider => provider.modelId === providerId);
            
            if (providerIndex !== -1) {
                if (!state.aiModels.providers[providerIndex].provider_keys) {
                    state.aiModels.providers[providerIndex].provider_keys = [];
                }
                if (state.aiModels.providers[providerIndex].provider_key === null) {
                    state.aiModels.providers[providerIndex].provider_key = newProviderKey;
                } else {
                    if (!state.aiModels.providers[providerIndex].provider_keys.includes(newProviderKey)) {
                        state.aiModels.providers[providerIndex].provider_keys.push(newProviderKey);
                    }
                }
            }
        },
        updateKey: (state, action) => {
            const { providerId, key } = action.payload;
            state.aiModels.providers.find((p) => p.modelId === providerId).provider_key = key;
        },
        changeProvider: (state, action) => {
            const { providerId } = action.payload;
            state.aiModels.providers = state.aiModels.providers.map((provider) => {
                if (provider.modelId === providerId) {
                    return {
                        ...provider,
                        currentUsed: true
                    }
                } else {
                    return {
                        ...provider,
                        currentUsed: false
                    }
                }
            })
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getModelDetails.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.aiModels = action.payload;
            })
            .addCase(getModelDetails.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(getModelDetails.rejected, (state, action) => {
                state.status = "failed";
            });
    },
});


export const aiModelReducer = aiModels.reducer;
export const { addNewProviderKey,updateModel,removeModel,changeModel, addNewModel, addNewProvider, updateKey, changeProvider } = aiModels.actions;

import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SocketProvider } from "../context/SocketProvider";
import { jwtDecode } from "jwt-decode";
import { useAppSelector, useAppDispatch } from "../store";
import { getUserDetails } from "../store/authSlice";
import { getOrganization } from "../store/organization";
import { PuffLoader } from "react-spinners";
import Cookies from "js-cookie";

function PrivateRouteWrapper() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pathname = searchParams.get("token");

  const { userDetailsState, organizationState } = useAppSelector((state) => ({
    userDetailsState: state.auth.userDetails,
    organizationState: state.organization.organization,
  }));

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    let decodedToken = "";

    if (token) {
      decodedToken = jwtDecode(token);
      if (decodedToken?.userId) {
        dispatch(getOrganization());
        dispatch(getUserDetails(decodedToken?.userId));
      }
    } else {
      // navigate("/signin", { state: { redirecturl: pathname } });
      navigate("/signin");
    }
  }, []);

  return (
    <SocketProvider>
      {userDetailsState && organizationState.length > 0 ? (
        <Outlet />
      ) : (
        <div className="spinner w-screen h-screen flex items-center justify-center">
          <PuffLoader
            color={
              userDetailsState?.primaryColor ||
              organizationState[0]?.primaryColor
            }
            cssOverride={null}
            loading
            size={60}
          />
          <h1 className="text-3xl ms-3 font-bold">Loading...</h1>
        </div>
      )}
    </SocketProvider>
  );
}

export default PrivateRouteWrapper;

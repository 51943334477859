import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getTeams, updateTeamNotification } from "../../store/teamSlice";
import apiClient from "../../utils/axiosInstance";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useAppDispatch, useAppSelector } from "../../store";
import { useEffect, useState } from "react";
import TeamModal from "../Team/TeamModal";
import { jwtDecode } from "jwt-decode";
import { IoAdd } from "react-icons/io5";


function SidebarRenderSpace({ isItemActive, setActiveItemId }) {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { teamState, teamStatus, organizationState } = useAppSelector(
    (state) => ({
      teamState: state.teams.teams,
      teamStatus: state.teams.teamStatus,
      organizationState: state.organization.organization,
    })
  );
  const navigate = useNavigate();
  const [teamModal, setTeamModal] = useState(false);
  const [initialTeam, setInitialTeam] = useState(null);

  const { token } = useParams();

  const [error, setError] = useState(null);

  const teams = teamState?.map((team) => {
    return {
      id: team.teamId,
      name: team.teamName,
      notification: team.notification,
      teamType: team.teamType,
    };
  });

  useEffect(() => {
    if (!teamState?.length > 0) {
      dispatch(getTeams());
    }
  }, []);

  useEffect(() => {
    if (teamState?.length > 0 && location.pathname === "/team") {
      const team = teamState[0];
      setActiveItemId(team.teamId);
      navigate(`/team?id=${team.teamId}`);
    }
  }, []);

  const handleItemClick = (itemId) => {
    setActiveItemId(itemId);
  };

  const resetNotification = async (teamId) => {
    try {
      await apiClient().put(`/reset-notification/${teamId}`);
      dispatch(updateTeamNotification({ teamId: teamId, notification: 0 }));
    } catch (error) {
      console.log("error in reset notification:", error);
    }
  };
  return (
    <div>
      <ul className="space-y-2 px-3 py-1 ">
        <li>
          <div className="flex items-center px-3 justify-between py-5 border-b-2">
            <p className="text-xl font-bold">Spaces</p>
            {/* <button onClick={() => { setTeamModal(true) }}>
              <div className="flex items-center justify-center p-2 rounded-md" style={{backgroundColor:"#e5e7eb"}}><IoAdd/></div>
            </button> */}
          </div>
        </li>

        {teamStatus === "loading" ? (
          <div className="m-2 rounded">
            <div className="flex items-start">
              <div className="flex-1">
                <div className="w-full h-4 bg-gray-200 rounded mb-2 animate-pulse"></div>
                <div className="w-full h-4 bg-gray-200 rounded mb-1 animate-pulse"></div>
                <div className="w-full h-4 bg-gray-200 rounded animate-pulse"></div>
              </div>
            </div>
          </div>
        ) : (
          teams.map((item, index) => {
            return (
              <li
                key={index}
                className={`text-sm flex justify-between items-center p-1 rounded-lg  transition-colors duration-200 hover:bg-gray-200 `}
                style={{
                  background: isItemActive(item.id)
                    ? organizationState[0]?.secondaryColor
                    : "",
                }}
              >
                <Icon
                  icon={
                    item.teamType === "public"
                      ? "solar:hashtag-linear"
                      : "mdi:lock"
                  }
                  width="1.5em"
                  height="1.5em"
                  className="text-pricingText mr-2"
                  style={{
                    color: isItemActive(item.id) ? "white" : "black",
                  }}
                />
                <Link
                  onClick={() => {
                    resetNotification(item.id);
                    handleItemClick(item.id);
                  }}
                  to={`/team?id=${item.id}`}
                  style={{
                    color: isItemActive(item.id) ? "white" : "black",
                  }}
                  className={`truncate w-52  flex-grow text-landing capitalize  font-normal ${
                    item.notification > 0 && "text-gray-900 font-semibold"
                  }`}
                >
                  {item.name}
                </Link>
                {item.notification > 0 && (
                  <p className="bg-red-500 text-white text-xs font-bold w-5 h-5 p-[0.5px] rounded-full ml-2 border-b-2 text-center">
                    {item.notification}
                  </p>
                )}
              </li>
            );
          })
        )}
        <div className="flex items-center gap-2 py-2 rounded mt-2">
          <button
            className="text-center text-sm flex items-center gap-2"
            onClick={() => {
              setTeamModal(true);
            }}
          >
            <div className="flex items-center justify-center p-2 rounded-md" style={{backgroundColor:"#e5e7eb"}}><IoAdd/></div>
            Create new space
          </button>
        </div>
        {teamState?.length > 0 && (
          <button
            className=" font-normal  text-center ml-2 border-b-2 "
            style={{
              color: `${organizationState[0]?.primaryColor}`,
              borderColor: `${organizationState[0]?.primaryColor}`,
            }}
            onClick={() => navigate("/teams")}
          >
            View All
          </button>
        )}
      </ul>
      <TeamModal
        teamModal={teamModal}
        setTeamModal={setTeamModal}
        initialTeam={initialTeam}
        setInitialTeam={setInitialTeam}
      />
    </div>
  );
}

export default SidebarRenderSpace;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../utils/axiosInstance";
import { toast } from "react-toastify";

const initialState = {
  folders: [],
  documents: [],
  documentChats: [],
  documentChatStatus: null,
  documentStatus: null,
  folderStatus: null,
};

export const getFolders = createAsyncThunk("getFolders", async () => {
  try {
    const response = await apiClient().get(`/folders`);
    return response.data;
  } catch (error) {
    console.log("error in chat slice:", error);
    toast.error(`${error}`);
    throw error;
  }
});

export const getDocumentChats = createAsyncThunk(
  "getDocumentChats",
  async (threadId) => {
    try {
      const response = await apiClient().get(`/get-document-chats/${threadId}`);
      return response.data;
    } catch (error) {
      console.log("error in chat slice:", error);
      toast.error(`${error}`);
      throw error;
    }
  }
);

export const getDocuments = createAsyncThunk(
  "getDocuments",
  async (folderId) => {
    try {
      const response = await apiClient().get(`/documents/${folderId}`);
      return response.data;
    } catch (error) {
      console.log("error in chat slice:", error);
      toast.error(`${error}`);
      throw error;
    }
  }
);

export const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    addFolder: (state, action) => {
      state.folders.folders = [...state.folders.folders, action.payload];
    },
    updateFolderName: (state, action) => {
      state.folders.folders = state.folders.folders.map((folder) =>
        folder.folderId === action.payload.folderId
          ? { ...folder, folderName: action.payload.folderName }
          : folder
      );
    },
    addDocument: (state, action) => {
      state.documents.documents = [
        ...state.documents.documents,
        action.payload,
      ];
    },
    removeDocument: (state, action) => {
      state.documents.documents = state.documents.documents.filter(
        (document) => document.documentId !== action.payload
      );
    },
    removeFolder: (state, action) => {
      state.folders.folders = state.folders.folders.filter(
        (folder) => folder.folderId !== action.payload
      );
    },
    documentChat: (state, action) => {
      state.documentChats.chats = [
        ...state.documentChats.chats,
        action.payload,
      ];
    },
    likedDocumentChat: (state, action) => {
      state.documentChats.chats = state.documentChats.chats.map((chat) => {
        if (chat.messageId === action.payload) {
          return { ...chat, liked: 1 };
        }
        return chat;
      });
    },
    reportDocumentChat: (state, action) => {
      state.documentChats.chats = state.documentChats.chats.map((chat) => {
        if (chat.messageId === action.payload) {
          return { ...chat, report: 1 };
        }
        return chat;
      });
    },
    resetDocumentChats: (state) => {
      state.documentChats = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFolders.fulfilled, (state, action) => {
        state.folderStatus = "succeeded";
        state.folders = action.payload;
      })
      .addCase(getFolders.pending, (state, action) => {
        state.folderStatus = "loading";
      })
      .addCase(getFolders.rejected, (state, action) => {
        state.folderStatus = "failed";
      })
      .addCase(getDocuments.fulfilled, (state, action) => {
        state.documentStatus = "succeeded";
        state.documents = action.payload;
      })
      .addCase(getDocuments.pending, (state, action) => {
        state.documentStatus = "loading";
      })
      .addCase(getDocuments.rejected, (state, action) => {
        state.documentStatus = "failed";
      })
      .addCase(getDocumentChats.fulfilled, (state, action) => {
        state.documentChatStatus = "succeeded";
        state.documentChats = action.payload;
      })
      .addCase(getDocumentChats.pending, (state, action) => {
        state.documentChatStatus = "loading";
      })
      .addCase(getDocumentChats.rejected, (state, action) => {
        state.documentChatStatus = "failed";
      });
  },
});

export const {
  addFolder,
  updateFolderName,
  addDocument,
  removeDocument,
  removeFolder,
  likedDocumentChat,
  documentChat,
  reportDocumentChat,
  resetDocumentChats,
} = documentSlice.actions;

export const documentReducer = documentSlice.reducer;

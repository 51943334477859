import React from "react";

function ErrorPage() {
  return (
    <div className="bg-gray-100 flex flex-col justify-center items-center h-screen">
      <div className="max-w-md p-8 bg-white rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-red-600 mb-4">404 Error</h1>
        <p className="text-lg text-gray-700 mb-4">
          Oops! The page you're looking for does not exist.
        </p>
      </div>
    </div>
  );
}

export default ErrorPage;

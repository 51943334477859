import React, { useEffect, useState } from "react";
import Chat from "../Components/Chat";
import Sidebar from "../Components/Sidebar";
import DashboardSidebar from "./DashboardSidebar";
import { getUserDetails } from "../store/authSlice";
import useAuth from "../utils/useAuth";
import { useAppDispatch, useAppSelector } from "../store";
import { getOrganization } from "../store/organization";

function Dashboard() {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { userDetailsState, organizationState } = useAppSelector((state) => ({
    userDetailsState: state.auth.userDetails,
    organizationState: state.organization.organization,
  }));
  const [dashboard, SetDashboard] = useState(false);
  const [isOpenWebModal, setIsOpenWebModal] = useState(false);

  useEffect(() => {
    if (user && !userDetailsState) {
      dispatch(getUserDetails(user?.userId));
    }
  }, [user]);

  useEffect(() => {
    if (organizationState?.length === 0) {
      dispatch(getOrganization());
    }
  }, []);

  return (
    <>
      <div className={`dashboard-chat-container w-full my-pb-20 ${dashboard ? "block" : "hidden"} md:block`}>
        <Chat
          SetDashboard={SetDashboard}
        />
      </div>
    </>
  );
}

export default Dashboard;
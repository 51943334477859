import React, { useState, useEffect } from 'react';
import apiClient from '../utils/axiosInstance';
import { Icon } from '@iconify/react/dist/iconify.js';

const MessageLinkPreview = ({ url, onClose }) => {
    const [preview, setPreview] = useState(null);

    useEffect(() => {
        const fetchPreview = async () => {
            try {
                const response = await apiClient().post("/link-preview", { url });
                setPreview(response.data);
            } catch (error) {
                console.error('Error fetching link preview:', error);
            }
        };

        if (url) {
            fetchPreview();
        }
    }, [url]);

    if (!preview) return null;

    return (

        <div className="ml-12 mt-4 relative w-full max-w-96 rounded-md overflow-hidden">
            <div className='flex items-start'>
                <button
                    onClick={() => onClose(url)}
                    className="text-black pr-1"
                    aria-label="Close preview"
                >
                    <Icon icon="line-md:menu-to-close-transition" className="w-4 h-4" />
                </button>
                <div className="border-l-2 pl-2 border-slate-200">
                    <h3 className="font-semibold text-sm  mb-1 truncate text-blue-700">{preview.title}</h3>
                    <p className="text-xs text-black mb-2 line-clamp-2">{preview.description}</p>
                    <a href={url} target="_blank" rel="noopener noreferrer" className="text-blue-700 text-xs hover:underline truncate block">
                        {new URL(url).hostname}
                    </a>
                </div>
            </div>


        </div>
    );
};

export default MessageLinkPreview;
import { useEffect, useRef, useState } from "react";
import apiClient from "../../utils/axiosInstance";
import { useAppDispatch } from "../../store";
import { Icon } from "@iconify/react/dist/iconify.js";
import { updateOrganizationKeyValue, uploadLogo } from "../../store/organization";
import { format } from "date-fns";
import { debounce } from "lodash";

const OrganizationSettings = ({ userDetailsState, org }) => {
    const dispatch = useAppDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState(null);


    const [newOrgName, setNewOrgName] = useState(org?.organizationName);
    const [primary, setPrimary] = useState(org?.primaryColor || "#0D2425");
    const [secondary, setSecondary] = useState(org?.secondaryColor || "#000000");

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        try {
            if (newOrgName === org?.organizationName) {
                setIsEditing(false);
                return;
            }
            const response = await apiClient().put("/organization", {
                organizationName: newOrgName,
            })
            dispatch(updateOrganizationKeyValue({ key: "organizationName", value: response.data.organizationName }));
        } catch (error) {
            console.error("Error updating org name:", error);
        } finally {
            setIsEditing(false);
        }
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setIsUploading(true);
        setError(null);

        const formData = new FormData();
        formData.append('logo', file);

        try {
            const config = { headers: { "Content-Type": "multipart/form-data" } };
            const response = await apiClient().post('/logo', formData, config);
            dispatch(uploadLogo(response.data));
        } catch (err) {
            setError('Failed to upload logo. Please try again.');
        } finally {
            setIsUploading(false);
        }
    };

    const handlePrimaryColorChange = debounce(async (newColor) => {
        await apiClient().post("/color-config", { primaryColor: newColor });
        dispatch(updateOrganizationKeyValue({ key: "primaryColor", value: newColor }));
    }, 500);

    const handleSecondaryColorChange = debounce(async (newColor) => {
        await apiClient().post("/color-config", { secondaryColor: newColor });
        dispatch(updateOrganizationKeyValue({ key: "secondaryColor", value: newColor }));
    }, 500);

    useEffect(() => {
        handlePrimaryColorChange(primary);
        return () => handlePrimaryColorChange.cancel();
      }, [primary]);
    
      useEffect(() => {
        handleSecondaryColorChange(secondary);
        return () => handleSecondaryColorChange.cancel();
      }, [secondary]);
    

    const fileInputRef = useRef(null);
    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        <>
            <div>
                <div className="flex items-center w-full justify-between border-b-2 border-borderColor mt-5 pb-5">
                    <p className="text-lg font-normal text-secondary w-[40%]">Logo</p>
                    <div className="flex items-center w-[40%]">
                        <div className="flex ">
                            {(!org?.logoUrl && !org?.logoS3Loc) ? (
                                <div className="">
                                    <p className="text-base font-normal text-textPrimary">
                                        Upload your logo
                                    </p>
                                </div>
                            ) : (
                                <div className="">
                                    <p className="text-base font-normal text-textPrimary bg-slate-100">
                                        <img src={org?.logoUrl} alt="logo" className="h-10 w-20  object-fit" />
                                    </p>
                                </div>
                            )}
                            <label htmlFor="logo-upload" className="cursor-pointer">
                                {/* <Icon
                                    icon="solar:upload-minimalistic-bold"
                                    className={`w-6 h-6 ${isUploading ? 'animate-pulse' : ''}`}
                                /> */}
                            </label>
                            
                            <input
                                id="logo-upload"
                                type="file"
                                accept="image/*"
                                className="hidden"
                                ref={fileInputRef}
                                onChange={handleFileUpload}
                                disabled={isUploading}
                            />
                        </div>
                        {error && (
                            <p className="text-red-500 text-sm mt-2">{error}</p>
                        )}
                    </div>
                    <div className="w-[20%]">
                    {
                        userDetailsState?.isRootAdmin &&
                            <button onClick={handleButtonClick} className="" style={{ color: org?.primaryColor }} >Update</button>
                        }
                        </div>
                </div>
                <div className="flex items-center justify-between border-b-2 border-borderColor mt-5 pb-5">
                    <p className="text-lg font-normal text-secondary w-[40%]">Organization Name</p>
                    <div className="flex items-center w-[40%]">
                        {isEditing ? (
                            <>
                                <input
                                    type="text"
                                    value={newOrgName}
                                    onChange={(e) => setNewOrgName(e.target.value)}
                                    className="text-base font-normal text-textPrimary mr-2 p-1 border rounded"
                                />
                            </>
                        ) : (
                            <>
                                <p className="text-base font-normal text-textPrimary mr-2">
                                    {org?.organizationName}
                                </p>
                            </>
                        )}
                    </div>
                    <div className="w-[20%]">
                        {isEditing ? (
                            <button onClick={handleSave} style={{ color: org?.primaryColor }}>Save</button>
                        ) : (
                            userDetailsState?.isRootAdmin && (
                                <button onClick={handleEdit} style={{ color: org?.primaryColor }}>Update</button>
                            )
                        )}
                    </div>

                </div>

                <div className="flex items-center justify-between mt-5 pb-5 border-b-2 border-borderColor">
                    <p className="text-lg font-normal text-secondary w-[40%] ">Created on</p>
                    <div className="flex w-[40%]">
                        <p className="text-base font-normal text-textPrimary">
                            {format(new Date(userDetailsState?.createdAt), "MM/dd/yyyy")}
                        </p>
                    </div>
                    <div className="w-[20%]"></div>
                </div>
                <div className="flex items-center justify-between mt-5 pb-5">
                    <p className={`text-lg font-normal text-secondary w-[40%]`} >
                        Colors
                    </p>
                    <p className="w-[40%]">Primary</p>
                    <div className="flex items-center w-[20%] bg-transparent border-transparent">
                        <p className=" "> 
                            <input type="color" className="rounded-full" value={primary} disabled={!userDetailsState?.isRootAdmin} onChange={(e) => {
                                setPrimary(e.target.value)
                            }} />
                        </p>
                    </div>
                </div>
                <div className="flex items-center justify-between pb-5">
                    <p className="text-lg font-normal text-secondary w-[40%]">
                    </p>
                    <p className="w-[40%] ">Secondary</p>
                    <div className="flex items-center w-[20%] bg-transparent border-transparent">
                        <p>
                            <input type="color" className="rounded-full" value={secondary} disabled={!userDetailsState?.isRootAdmin} onChange={(e) => {
                                setSecondary(e.target.value)
                            }} />
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}


export default OrganizationSettings;
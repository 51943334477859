import React from "react";
function ChatLoader() {
  return (
    <div className="flex justify-center items-center mb-5">
      <div className="col-3">
        <div className="snippet" data-title="dot-typing">
          <div className="stage flex items-center bg-borderColor rounded-full px-2 py-2 w-40">
            <p className="text-md text-textPrimary font-medium pl-2">
              Searching
            </p>
            <div className="dot-typing"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatLoader;

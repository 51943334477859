import Footer from "../Components/HomePage/Footer";
import Header from "../Components/HomePage/Header";

function Contact() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex-grow">
        <div className="bg-white mt-10 mb-20">
          <div className="flex items-center flex-col justify-center mb-8">
            <h1 className="text-4xl font-bold">Contact us</h1>
          </div>

          <div className="flex items-center flex-col justify-center ">
            {/* <p className="text-lg font-medium mt-5">
              For business & partnership inquiries, please contact
              <span style={{color:"#7369a7"}}> evan@fintwit.ai </span>
            </p> */}
            <p className="text-lg font-medium mt-5">
              For accounts and other inquiries, please contact
              <span style={{color:"#7369a7"}}> support@cospace.ai </span>
            </p>
            <p className="text-lg font-medium mt-5">
              Follow us on Twitter
              <span style={{color:"#7369a7"}} className="hover:underline cursor-pointer"><a href="https://twitter.com/cospaceai" target="_blank" > @cospaceai</a> </span>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;

import { Icon } from "@iconify/react/dist/iconify.js";
import { useAppDispatch, useAppSelector } from "../store";
import { useEffect, useState } from "react";
import { newQuery, updateStarredThread } from "../store/finChat";
import apiClient from "../utils/axiosInstance";
import { toast } from "react-toastify";

function ChatBox({ setIsChatLoading, threadId, setQuestion, questions }) {
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState("");
  const { organizationState, isPlanActive } = useAppSelector((state) => ({
    organizationState: state.organization.organization,
    isPlanActive: state.auth.isPlanActive,
  }));

  const handleChat = async () => {
    setQuestion(query);
    setQuery("");
    setIsChatLoading(true);
    try {
      const body = {
        action: "chat",
        query: query,
        threadId: threadId,
        previous_queries: questions,
        createdAt: new Date().toISOString(),
      };
      const response = await apiClient().post(`/chat`, body);
      dispatch(
        updateStarredThread({
          threadId: response.data.threadId,
          key: ["updatedAt"],
          value: [response.data.updatedAt],
        })
      );
      dispatch(newQuery(response.data));
      setQuestion("");
    } catch (error) {
      console.log("error in chat:", error);
    } finally {
      setIsChatLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <div className="sticky bottom-0 bg-[#f3f3ee] p-2 pb-20 md:pb-0 xl:pb-0">
      <div className="mb-5">
        <div className="p-2 border border-borderColor  bg-backgroundColor rounded-full mb-5">
          <div className="input-container flex items-center ">
            <input
              type="text"
              value={query}
              onChange={handleInputChange}
              className="form-input px-4 py-2 w-full border-none focus:outline-none focus:ring-0 focus:border-none"
              placeholder="Ask anything..."
              onKeyDown={(e) => {
                if (e?.key === "Enter") handleChat();
              }}
            />
            <div>
              <button
                onClick={() => {
                  if (isPlanActive) {
                    handleChat();
                  } else {
                    toast.error("Please upgrade your plan to use this feature");
                  }
                }}
                className={`flex items-center p-2 ml-5 ${
                  !query ? "" : "text-white"
                } rounded-full font-normal text-lg group`}
                style={{
                  backgroundColor: !query
                    ? "bg-gray-100"
                    : organizationState[0]?.primaryColor,
                }}
              >
                <Icon icon="fluent:arrow-up-48-filled" className="text-xl" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatBox;

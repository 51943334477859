import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../store/index";
import { setAuthState } from "../store/authSlice";
import { toast } from "react-toastify";
import apiClient from "../utils/axiosInstance";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { usePostHog } from "posthog-js/react";
import { useGoogleLogin } from "@react-oauth/google";
import * as Yup from "yup";
import axios from "axios";
// import AppleSignin from "react-apple-signin-auth";
import { jwtDecode } from "jwt-decode";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";
import SpinnerLoading from "../Components/SpinnerLoading";

import { GoTriangleRight } from "react-icons/go";
import Header from "../Components/HomePage/Header";
import { FcGoogle } from "react-icons/fc";

const IndividualPlan = [
  { title: "Smart Team Collaboration" },
  { title: "Real time Answer Engine" },
  { title: "Your AI, Your Data" },
  { title: "Chat with Documents" },
  { title: "Custom Branding" },
];

function SignIn() {
  const location = useLocation();
  const redirectUrl = location?.state?.redirecturl || "";
  // const decoded = jwtDecode(redirectUrl);

  const posthog = usePostHog();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const state = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const token = localStorage.getItem("authToken");
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const chackuser = async (email) => {
    try {
      const formData = new FormData();
      formData.append("email", email);
      if (email) {
        const response = await apiClient().post(
          `${process.env.REACT_APP_API_URL}/check-profile`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // if (redirectUrl) {
        //   navigate("/team?id=" + decoded.teamId + "&token=" + redirectUrl);
        // } else {
        navigate("/dashboard");
        // }
      }
    } catch (error) {
      navigate(`/create-profile/${token}`);
    }
  };

  const handleEmailAuth = async (values) => {
    const { email, password } = values;
    try {
      setLoading(true);
      const response = await apiClient().post(
        `${process.env.REACT_APP_API_URL}/login`,
        {
          email: email,
          password: password,
        }
      );
      if (response && response.data) {
        localStorage.setItem("authToken", response.data.token);
        dispatch(setAuthState(true));
        await chackuser(email);
      }
    } catch (error) {
      toast.error(error);
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const signInWithGoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const googleApiResponse = await axios.get(
          `${process.env.REACT_APP_GOOGLE_API}?access_token=${codeResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: "application/json",
            },
          }
        );

        const result = await axios.post(
          `${process.env.REACT_APP_API_URL}/sign-in-with-services`,
          {
            service: "google",
            name: googleApiResponse.data.name,
            email: googleApiResponse.data.email,
            issignup: false,
          }
        );

        if (result && result.data) {
          dispatch(setAuthState(true));
          if (result.data.isFirstTime) {
            navigate(`/create-profile/${result.data.token}`);
          } else {
            localStorage.setItem("authToken", result.data.token);
            navigate("/dashboard");
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    onError: (error) => {
      console.log("Login Failed:", error);
    },
  });

  const successResponse = async (response) => {
    setLoading(true);
    let data = {};
    try {
      const decodedData = jwtDecode(response.authorization.id_token);

      data = {
        name: response.user ? response.user.name : "",
        email: response.user ? response.user.email : decodedData.email,
      };

      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/sign-in-with-services`,
        {
          service: "apple",
          name: data.name || "",
          email: data.email,
        }
      );

      if (result && result.data) {
        dispatch(setAuthState(true));
        if (result.data.isFirstTime) {
          navigate(`/create-profile/${result.data.token}`);
        } else {
          localStorage.setItem("authToken", result.data.token);
          navigate("/dashboard");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const features = [
    {
      title: "Real-time Answer Engine",
      description:
        "Cospace.ai includes a real-time answer engine that utilizes real-time web data to provide accurate and timely responses to your queries.",
    },
    {
      title: "Chat with Documents",
      description:
        "Chat with your documents for real-time AI summaries and insights. Cospace.ai helps you quickly digest complex information.",
    },
    {
      title: "Your AI, Your Data",
      description:
        "With Cospace.ai, you can integrate with popular LLMs by simply using your own API key.",
    },
    {
      title: "Smart Team Collaboration",
      description:
        "Cospace.ai comes with all the basic chat features such as group chats, DMs, notifications, and file sharing.",
    },
    {
      title: "Custom Branding",
      description:
        "Make Cospace.ai your own by customizing the logo and color palette to match your brand. It seamlessly adapts to your private cloud or public SaaS setup.",
    },
  ];

  return (
    <>
      {loading && <SpinnerLoading isLoading={loading} />}
      <div>
        <Header />
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 ">
          <div className="md:col-span-6 px-4 md:px-0 flex items-center bg-[#f7f1ff]">
            <div className="w-[28rem] mx-auto p-6  rounded-lg dark:bg-gray-800 mt-8">
              <h2 className="text-2xl font-semibold mb-6  dark:text-white">
                Sign in to your account
              </h2>
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Invalid email")
                    .required("Email is required"),
                  password: Yup.string().required("Password is required"),
                })}
                onSubmit={handleEmailAuth}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="mb-6">
                      <label
                        htmlFor="email"
                        className="block mb-2 text-sm font-medium text-[#7e7e7e] dark:text-gray-300"
                      >
                        Email address
                      </label>
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        className="w-full px-3 py-2 text-gray-700 bg-gray-100 rounded-[20px] focus:outline-none focus:ring-2 focus:ring-lendingButton dark:bg-gray-700 dark:text-white"
                        placeholder="you@example.com"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500 mt-1 text-sm"
                      />
                    </div>
                    <div className="mb-6">
                      <label
                        htmlFor="password"
                        className="block mb-2 text-sm font-medium text-[#7e7e7e] dark:text-gray-300"
                      >
                        Password
                      </label>
                      <div className="relative">
                        <Field
                          type={showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          className="w-full px-3 py-2 text-gray-700 bg-gray-100 rounded-[20px] focus:outline-none focus:ring-2 focus:ring-lendingButton dark:bg-gray-700 dark:text-white"
                          placeholder="Password"
                        />
                        <span
                          className="absolute inset-y-0 right-0 flex items-center pr-4 cursor-pointer"
                          onClick={toggleShowPassword}
                        >
                          <Icon
                            icon={showPassword ? "mdi:eye-off" : "mdi:eye"}
                            className="text-gray-400 w-6 h-6"
                          />
                        </span>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-500 mt-1 text-sm"
                      />
                    </div>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="flex items-center justify-center w-full px-4 py-2 text-white bg-gradient-to-r from-[#8934E9] to-[#5844E6] rounded-[20px] focus:outline-none focus:ring-2 focus:ring-lendingButton focus:ring-offset-2 transition-colors duration-300 ease-in-out"
                    >
                      Sign In <GoTriangleRight />
                    </button>
                  </Form>
                )}
              </Formik>
              <>
                <div className="flex items-center w-full justify-between">
                  <hr className="my-6 border-gray-300 w-[45%] dark:border-gray-600" />
                  <span> or </span>
                  <hr className="my-6 border-gray-300 w-[45%] dark:border-gray-600" />
                </div>
                {/* <button
                  onClick={() => signInWithGoogle()}
                  className="flex items-center m-auto justify-center mt-5 p-2 w-full md:w-96 text-gray-900 text-center rounded-full font-normal text-lg bg-allBorderColor hover:bg-lendingButton hover:text-white"
                >
                  <Icon icon="prime:google" className="text-xl" />
                  Sign in with Google
                </button> */}

                <button
                  onClick={() => signInWithGoogle()}
                  className="flex items-center justify-center  p-2 w-full  text-[#2A2A2A] text-center rounded-full border border-[#7E7E7E] text-md font-medium bg-transparent hover:bg-gradient-to-r hover:from-[#8934E9] hover:to-[#5844E6] hover:text-white"
                >
                  Continue with <FcGoogle className="ms-2 text-xl" />
                </button>
              </>
            </div>
          </div>
          <div
            className="md:col-span-6  px-8 text-white flex items-start gap-10 justify-center flex-col"
            style={{ height: "calc(100vh - 81px)" }}
          >
            <div className="feature-list-container max-w-2xl mx-auto px-4 py-8">
              {features.map((feature, index) => (
                <div
                  key={index}
                  className="feature-item flex items-start mb-10"
                >
                  <span className="check-icon text-purple-500 mr-4">✔</span>
                  <div>
                    <h3 className="feature-title font-semibold text-lg text-[#2A2A2A]">
                      {feature.title}
                    </h3>
                    <p className="feature-description text-[#7E7E7E]">
                      {feature.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;

import { useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import moment from "moment";
import { getTeams, removeTeam } from "../store/teamSlice";
import Members from "../Components/Team/Members";
import InviteModal from "../Components/sidebar/InviteModal";
import TeamModal from "../Components/Team/TeamModal";
import SpinnerLoading from "../Components/SpinnerLoading";
import apiClient from "../utils/axiosInstance";
import { toast } from "react-toastify";
import Modal from "../Components/Modal";
import DashboardSidebar from "./DashboardSidebar";
import { useAppDispatch, useAppSelector } from "../store";

const ThreadSkeleton = () => (
  <div className="px-4 md:px-8 lg:px-8 mb-4">
    <div className="border-t-2 border-borderColor">
      <div className="flex items-center justify-between mb-3 mt-3">
        <div className="h-6 bg-gray-200 rounded w-3/4"></div>
      </div>
    </div>
    <div className="flex items-center mt-3">
      <div className="flex items-center mb-5">
        <div className="h-5 w-5 bg-gray-200 rounded-full mr-2"></div>
        <div className="h-4 bg-gray-200 rounded w-32"></div>
      </div>
    </div>
  </div>
);

function Teams() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [teamModal, setTeamModal] = useState(false);
  const [inviteModule, setInviteModule] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteTeamId, setDeleteTeamId] = useState(null);
  const [initialTeam, setInitialTeam] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTeam , setSelectedTeam] = useState(null)

  const { teamState, teamStatus } = useAppSelector((state) => ({
    teamState: state.teams.teams,
    teamStatus: state.teams.teamStatus,
  }));

  useEffect(() => {
    if (teamState?.length === 0) {
      dispatch(getTeams());
    }
  }, []);

  const getChatByThreadId = (teamId) => {
    navigate(`/team?id=${teamId}`);
  };


  const confirmDeleteAccount = async () => {
    try {
      setIsLoading(true);
      await apiClient().delete(`/team/${deleteTeamId}`);
      dispatch(removeTeam(deleteTeamId));
      setShowModal(false);
    } catch (error) {
      toast.error("Error while deleting account");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditTeam = (team) => {
    setInitialTeam(team);
    setTeamModal(true);
  };

  const handleDeleteTeam = async (teamId) => {
    setDeleteTeamId(teamId);
    setShowModal(true);
  };

  return (
    <>
      {isLoading && <SpinnerLoading isLoading={true} />}
      {/* <div className="flex md:flex-row flex-col">
        <DashboardSidebar />
        <Sidebar /> */}
        <div className="bg-backgroundColor header-height w-full md:px-0 px-2 my-pb-20">
          <div className="">
            <div className="flex justify-between items-center py-4">
              <div className="flex items-center ml-8">
                <Icon icon="ri:team-line" fontSize={30} className="" />
                <p className="text-xl ml-2 text-textPrimary font-medium">Spaces</p>
              </div>
              <button
                onClick={() => {
                  setTeamModal(true);
                }}
                className="mr-8 rounded-full w-6 h-6 flex items-center justify-center hover:bg-gray-200"
              >
                <Icon icon="mdi:plus" width="1.8em" height="1.8em" />
              </button>
            </div>
            <div
              className="overflow-y-scroll"
              style={{ height: "calc(100vh - 130px" }}
            >
              {teamStatus === "loading"
                ? [...Array(5)].map((_, index) => <ThreadSkeleton key={index} />)
                : teamState?.map((team) => (
                  <div className="cursor-pointer border-t-2 border-borderColor" key={team.teamId}>
                    <div className="flex justify-between items-center py-1">
                      <div
                        className="font-medium ml-8 flex"
                        onClick={() => getChatByThreadId(team.teamId)}
                      >
                        <Icon
                          icon={team.teamType === "public" ? "solar:hashtag-linear" : "mdi:lock"}
                          width="1.5em"
                          height="1.5em"
                          className="text-pricingText mr-2"
                        />
                        <p className="text-md lg:text-lg font-medium ">
                          {team.teamName}
                        </p>
                      </div>
                      <div
                        className="flex items-center mr-8"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditTeam(team);
                        }}
                      >
                        <button className="mr-2 hover:bg-gray-300 rounded-3xl p-2 hover:text-lendingButton">
                          <Icon icon="tabler:edit" className="text-xl" />
                        </button>
                        <button
                          className="text-red-700 font-medium hover:text-red-400 rounded-3xl"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteTeam(team.teamId);
                          }}
                        >
                          <Icon
                            icon="fluent:delete-24-regular"
                            className="text-xl"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="flex justify-between items-center py-2">
                      <p
                        className="flex items-center ml-8"
                        onClick={() => getChatByThreadId(team.teamId)}
                      >
                        <Icon
                          icon="wi:time-4"
                          className="text-2xl text-secondary mr-2"
                        />
                        {moment(team.createdAt).fromNow()}
                      </p>
                      <div className="mr-8">
                        <Members
                          teamIds={[team.teamId]}
                          setInviteModule={setInviteModule}
                          setInitialTeam={setInitialTeam}
                          setSelectedTeam={setSelectedTeam}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      {/* </div> */}
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        confirmAction={confirmDeleteAccount}
        label={"Delete Space"}
        description={"Are you sure you want to delete this team?"}
        confirmText={"Confirm"}
        cancelText={"Cancel"}
      />
      <TeamModal
        teamModal={teamModal}
        setTeamModal={setTeamModal}
        initialTeam={initialTeam}
        setInitialTeam={setInitialTeam}
      />
      <InviteModal
        inviteModule={inviteModule}
        setInviteModule={setInviteModule}
        selectedTeam={selectedTeam}
      />
    </>
  );
}

export default Teams;
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import apiClient from "../utils/axiosInstance";

function JoinToTeam() {
  const { token } = useParams();
  const [decodedToken, setDecodedToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      if (!decoded.teamId || !decoded.email || !decoded.teamName) {
        setError("Invalid token. Please try again.");
        setIsLoading(false);
        return;
      }
      setDecodedToken(decoded);
      const updateUserStatus = async () => {
        try {
          setIsLoading(true);
          await apiClient().put(
            `/accept-team-invite/${decoded.email}/${decoded.teamId}`
          );
          setIsLoading(false);
        } catch (err) {
          setError("Failed to update user status. Please try again.");
          setIsLoading(false);
        }
      };

      updateUserStatus();
    }
  }, [token]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="p-8 bg-white shadow-lg rounded-lg">
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
          <p className="mt-4 text-center text-gray-600">Joining team...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="p-8 bg-white shadow-lg rounded-lg">
          <h1 className="text-2xl font-bold text-red-500 mb-4">Error</h1>
          <p className="text-gray-600 mb-4">{error}</p>
          <Link
            to="/"
            className="inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
          >
            Go to Home
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="p-8 bg-white shadow-lg rounded-lg text-center">
        <h1 className="text-3xl font-bold text-gray-800 mb-4">
          Welcome to the team!
        </h1>
        {decodedToken?.teamName && (
          <p className="text-xl text-gray-600 mb-6">
            You have successfully joined{" "}
            <span className="font-semibold text-blue-500">
              {decodedToken?.teamName}
            </span>
            .
          </p>
        )}
        <div className="mb-8">
          <svg
            className="w-16 h-16 text-green-500 mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <Link
          to="/"
          className="inline-block bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition duration-300"
        >
          Go to Home
        </Link>
      </div>
    </div>
  );
}

export default JoinToTeam;

import Footer from "../Components/HomePage/Footer";
import Header from "../Components/HomePage/Header";
import { privacyContent, privacyPolicy } from "../utils/static";

function PrivacyPolicy() {
  return (
    <div className="overflow-x-hidden">
      <Header />
      <div className="w-full flex justify-center">
        <div className="Container bg-white">
          <div className="mt-10 mb-20 ">
            <div className="">
              <div className="flex items-center flex-col justify-center mb-8">
                <h1 className="text-2xl font-bold">Privacy Policy</h1>
                <p className="text-gray-500">Effective Date: June 1, 2024</p>
              </div>
              <div>
                {privacyContent.map((paragraph, index) => (
                  <p key={index} className="mb-5 text-base font-normal">
                    {paragraph.text}
                  </p>
                ))}
              </div>

              <div>
                {privacyPolicy.map((section, index) => (
                  <div key={index}>
                    <h2 className="text-2xl font-semibold mb-3 mt-8">
                      {section.title}
                    </h2>
                    {Array.isArray(section.description) ? (
                      <ul className="ml-5">
                        {section.description.map((item, idx) => (
                          <li key={idx}>
                            <h3 className="text-lg font-semibold mt-5">
                              {item.title}
                            </h3>
                            <ul className="ml-6">
                              {Array.isArray(item.des) ? (
                                item.des.map((desc, i) => (
                                  <li
                                    key={i}
                                    className="mt-1 mb-5 text-base font-normal"
                                  >
                                    {desc}
                                  </li>
                                ))
                              ) : (
                                <li className="mt-2 text-base font-normal">
                                  {item.des}
                                </li>
                              )}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>{section.description}</p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;

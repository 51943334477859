import { Icon } from "@iconify/react/dist/iconify.js";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import SpinnerLoading from "../SpinnerLoading";
import apiClient from "../../utils/axiosInstance";
import { toast } from "react-toastify";
import { addDocument } from "../../store/DocumentSlice";

function UploadDocumentFile({
  setDocumentIsModal,
  documentIsModal,
  folderId,
  selectedFile,
  setSelectedFile,
}) {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { organizationState } = useAppSelector((state) => ({
    organizationState: state.organization.organization,
  }));

  const closeModal = () => {
    setDocumentIsModal(false);
    setSelectedFile(null);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("document", selectedFile);
      formData.append("folderId", folderId);
      setIsLoading(true);
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const response = await apiClient().post("/document", formData, config);
      dispatch(addDocument(response.data.document));
      setTimeout(() => {
        setIsLoading(false);
        setDocumentIsModal(false);
        setSelectedFile(null);
      }, 5000);
    } catch (error) {
      toast.error(error);
      setDocumentIsModal(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <SpinnerLoading isLoading={isLoading} />}
      {documentIsModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="p-4 md:p-8 rounded-lg w-[350px] md:w-[600px] bg-white">
            <div className="justify-center">
              <div className="flex items-center justify-between mb-5">
                <p className="text-color text-3xl font-medium text-center">
                  Document
                </p>
                <div>
                  <button
                    onClick={closeModal}
                    className="flex items-center p-2 ml-auto text-secondary rounded-lg font-normal text-lg  hover:bg-gray-100  group"
                  >
                    <Icon icon="iconamoon:close-light" className="text-2xl" />
                  </button>
                </div>
              </div>
              <div>
                <div className="text-center border-2 border-borderColor rounded-lg p-3 cursor-pointer">
                  <div className="flex items-center justify-center cursor-pointer">
                    <label htmlFor="fileInput">
                      <div className="text-[50px] flex items-center justify-center ">
                        <Icon icon="la:file-upload" />
                      </div>
                      <input
                        type="file"
                        accept=".pdf,.docx,.txt,.jpg,.png"
                        id="fileInput"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                      <p className="text-xl font-medium text-secondary mt-5">
                        {selectedFile
                          ? `Selected File: ${selectedFile.name}`
                          : "Click to upload."}
                      </p>
                      <p className="text-xl font-medium text-secondary mt-3">
                        pdf ,docx ,text ,jpg ,png only supported
                      </p>
                    </label>
                  </div>
                </div>
                <div className="mt-5 text-end">
                  <button
                    className="mr-5 bg-gray-400 px-4 py-3 text-md font-normals rounded-lg text-white"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                  <button
                    className=" text-white text-md font-normal rounded-lg px-4 py-3"
                    style={{ backgroundColor: organizationState[0]?.primaryColor }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UploadDocumentFile;

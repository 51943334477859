import { Icon } from '@iconify/react/dist/iconify.js';
import React from 'react'
import { useAppSelector } from '../../store';
import moment from 'moment';

export const SharedByMeFile = () => {
  const { folderState, status, status2 } = useAppSelector((state) => ({
    folderState: state.document.folders,
    status: state.document.documentStatus,
    status2: state.document.folderStatus,
  }));

  const DocumentSkeleton = () => (
    <div className="border-t-2 border-borderColor px-8 py-4 animate-pulse">
      <div className="flex items-center justify-between">
        <div className="bg-gray-200 h-6 w-48 rounded"></div>
        <div className="flex">
          <div className="bg-gray-200 h-10 w-10 rounded-full mr-2"></div>
          <div className="bg-gray-200 h-10 w-10 rounded-full"></div>
        </div>
      </div>
      <div className="bg-gray-200 h-5 w-36 rounded mt-3"></div>
    </div>
  );

  const getFileIcon = (fileName) => {
    const extension = fileName?.split('.').pop().toLowerCase();
    switch (extension) {
      case 'docx':
        return "ph:file-doc-bold";
      case 'pdf':
        return "ph:file-pdf-bold";
      case 'txt':
        return "ph:file-txt-bold";
      case 'jpg':
      case 'jpeg':
        return "ph:file-jpg-bold";
      case 'png':
        return "ph:file-png-bold";
      default:
        return "ph:file-bold";
    }
  };
  const downloadFile = (url, fileName) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => console.error("Error downloading file:", error));
  };
  return (
    <div className="overflow-y-scroll px-8 py-6" style={{ height: "calc(100vh - 140px)" }} >
      {
        status === "loading" || status2 === "loading" ? (
          <>
            <DocumentSkeleton />
            <DocumentSkeleton />
            <DocumentSkeleton />
          </>
        ) :
          folderState?.sharedByMe?.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-full">
              <Icon icon="mdi:file-document-outline" className="text-6xl text-gray-400 mb-4" />
              <p className="text-xl text-gray-500 mb-4">No documents available</p>
            </div>
          ) : (
            folderState?.sharedByMe?.map((item) => {
              const formattedDate = moment.utc(item.createdAt).fromNow();
              return (
                <div key={item.documentId} >
                  <div className="border-t-2 border-borderColor">
                    <div className="px-8 text-lg flex items-center justify-between text-textPrimary font-medium mb-3 mt-3 relative">
                      <p className="text-lg text-textPrimary flex items-center font-medium mb-1 relative">
                        <Icon
                          icon={getFileIcon(item.documentName)}
                          className="text-2xl mr-2"
                        />
                        {item.files[0]?.fileName}
                      </p>
                      <div className="px-8 flex items-center">
                        <button onClick={() => downloadFile(item.files[0]?.url, item.files[0]?.fileName)} className="mr-2 hover:bg-gray-300 rounded-3xl p-2 hover:text-lendingButton"  >
                          <Icon icon="octicon:download-24" className="text-xl" />
                        </button>


                      </div>
                    </div>
                  </div>
                  <div className="flex items-center mt-3 border-borderColor px-8">
                    <p className="flex items-center mb-3">
                      <Icon icon="wi:time-4" className="text-3xl text-secondary mr-2" />
                      {formattedDate}
                    </p>
                  </div>
                </div>
              );
            })
          )}
    </div>
  )
}

import { useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useAppDispatch, useAppSelector } from "../../store";
import TeamModal from "../Team/TeamModal";
import apiClient from "../../utils/axiosInstance";
import useAuth from "../../utils/useAuth";
import SpinnerLoading from "../SpinnerLoading";
import { getOrganization } from "../../store/organization";
import { getTeams } from "../../store/teamSlice";

function ShareFolderModal({ setShareModal, shareModal, folder }) {
  const [expandedTeams, setExpandedTeams] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [activeTab, setActiveTab] = useState("teams");
  const dispatch = useAppDispatch();
  const { organizationState, status, teamState, teamStatus } = useAppSelector((state) => ({
    organizationState: state.organization.organization,
    status: state.organization.organizationStatus,
    teamState: state.teams.teams,
    teamStatus: state.teams.teamStatus,
  }));
  const [activeTeams, setActiveTeams] = useState([]);
  const [activeMembers, setActiveMembers] = useState([]);
  const [teamModal, setTeamModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    const fetchOrganization = () => {
      try {
        dispatch(getOrganization());
      } catch (error) {
        console.log(error);
      }
    };
    const fetchTeams = () => {
      try {
        dispatch(getTeams());
      } catch (error) {
        console.log(error);
      }
    }
    if (shareModal && organizationState.length === 0) {
      fetchOrganization();
    }
    if (shareModal && teamState.length === 0) {
      fetchTeams()
    }
  }, [shareModal, organizationState, teamState]);

  useEffect(() => {
    if (organizationState.length > 0 && user && teamState.length > 0) {
      const org = organizationState[0];

      const filteredTeams = teamState
        .map((team) => ({
          ...team,
          members: team.members.filter(
            (member) =>
              member.status === "active"
          ),
        }))
        .filter((team) => team.members.length > 0);

      setActiveTeams(filteredTeams);

      const filteredMembers = org.members.filter(
        (member) =>
          member.isAccepted && member.email !== user?.email
      );

      setActiveMembers(filteredMembers);
    }
  }, [organizationState, user, teamState]);

  const closeModal = () => {
    setShareModal(false);
    setExpandedTeams({});
    setSelectedItems({});
    setActiveTab("teams");
  };

  const toggleTeamExpansion = (teamId) => {
    setExpandedTeams((prev) => ({ ...prev, [teamId]: !prev[teamId] }));
  };

  const handleCheckboxChange = (id, email = null) => {
    setSelectedItems((prev) => {
      const newState = { ...prev };
      if (activeTab === "teams") {
        if (email) {
          newState[id] = { ...newState[id], [email]: !newState[id]?.[email] };
          const allMembersSelected = activeTeams
            .find((team) => team.teamId === id)
            .members.every((member) => newState[id][member.email]);
          newState[id].teamChecked = allMembersSelected;
        } else {
          const teamChecked = !newState[id]?.teamChecked;
          newState[id] = { teamChecked };
          activeTeams
            .find((team) => team.teamId === id)
            .members.forEach((member) => {
              newState[id][member.email] = teamChecked;
            });
        }
      } else {
        newState[id] = !newState[id];
      }
      return newState;
    });
  };

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
    setSelectedItems({});
    setExpandedTeams({});
  };

  const shareFolder = async () => {
    try {
      setIsLoading(true);
      const sharedWith = [];

      if (activeTab === "teams") {
        Object.entries(selectedItems).forEach(([teamId, team]) => {
          if (team.teamChecked) {
            sharedWith.push({ sharedTo: teamId, accountType: "team" });
          } else {
            activeTeams
              .find((t) => t.teamId === teamId)
              .members.forEach((member) => {
                if (team[member.email]) {
                  sharedWith.push({
                    sharedTo: member.userId,
                    accountType: "member",
                  });
                }
              });
          }
        });
      } else {
        Object.entries(selectedItems).forEach(([memberId, isSelected]) => {
          if (isSelected) {
            sharedWith.push({
              sharedTo: activeMembers.find(
                (member) => member.email === memberId
              ).userId,
              accountType: "member",
            });
          }
        });
      }

      const payload = {
        folderId: folder?.folderId,
        sharedBy: user?.userId,
        sharedWith,
        createdAt: new Date().toISOString(),
      };

      await apiClient().post("/share-folder", payload);
      setIsLoading(false);
      closeModal();
    } catch (error) {
      console.log("✌️error ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const isAnySelected = () => {
    if (activeTab === "teams") {
      return Object.values(selectedItems).some(
        (item) =>
          item.teamChecked ||
          Object.values(item).some((value) => value === true)
      );
    } else {
      return Object.values(selectedItems).some(Boolean);
    }
  };

  return (
    <>
      {isLoading && <SpinnerLoading isLoading={isLoading} />}
      {shareModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg overflow-hidden shadow-xl w-[350px] md:w-[600px] h-[500px] flex flex-col">
            {(status !== "loading" || teamStatus !== "loading") ? (
              <>
                <div className="p-6 border-b">
                  <div className="flex items-center justify-between">
                    <h2 className="text-xl font-semibold">
                      Share - {folder?.folderName}
                    </h2>
                    <button
                      onClick={closeModal}
                      className="p-2 hover:bg-gray-100 rounded-full"
                    >
                      <Icon icon="mdi:close" width="24" height="24" />
                    </button>
                  </div>
                </div>
                <div className="flex p-4 space-x-4">
                  <>
                    <button
                      className="flex-1 py-2 px-4 rounded-md font-medium transition-colors duration-200"
                      style={{
                        backgroundColor: activeTab === "teams" ? organizationState[0]?.primaryColor : "black",
                        color: activeTab === "teams" ? organizationState[0]?.secondaryColor : "white",
                      }}
                      onClick={() => handleTabChange("teams")}
                    >
                      Spaces
                    </button>
                    <button
                      className="flex-1 py-2 px-4 rounded-md font-medium transition-colors duration-200"
                      style={{
                        backgroundColor: activeTab === "members" ? organizationState[0]?.primaryColor : "black",
                        color: activeTab === "members" ? organizationState[0]?.secondaryColor : "white",
                      }}
                      onClick={() => handleTabChange("members")}
                    >
                      Members
                    </button>
                  </>

                </div>
                <div className="p-6 flex-grow overflow-y-auto">
                  {activeTab === "teams" ? (
                    activeTeams.map((team) => (
                      <div
                        key={team.teamId}
                        className="mb-4 border rounded-lg p-4 hover:bg-gray-50 transition-colors duration-200"
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-2">
                            <input
                              type="checkbox"
                              id={`team-${team.teamId}`}
                              checked={
                                selectedItems[team.teamId]?.teamChecked || false
                              }
                              onChange={() => handleCheckboxChange(team.teamId)}
                              className="w-5 h-5 rounded border-gray-300 focus:ring-lendingButton"
                              style={{ color: `${organizationState[0]?.primaryColor}` }}
                            />
                            <label
                              htmlFor={`team-${team.teamId}`}
                              className="font-medium text-gray-700"
                            >
                              {team.teamName}
                            </label>
                          </div>
                          <button
                            onClick={() => toggleTeamExpansion(team.teamId)}
                            className="p-1 hover:bg-gray-200 rounded-full transition-colors duration-200"
                          >
                            <Icon
                              icon={
                                expandedTeams[team.teamId]
                                  ? "mdi:chevron-up"
                                  : "mdi:chevron-down"
                              }
                              width="20"
                              height="20"
                            />
                          </button>
                        </div>
                        {expandedTeams[team.teamId] && (
                          <div className="mt-2 pl-6 space-y-2">
                            {team.members.map((member) => (
                              <div
                                key={member.email}
                                className="flex items-center space-x-2"
                              >
                                <input
                                  type="checkbox"
                                  id={`member-${team.teamId}-${member.email}`}
                                  checked={
                                    selectedItems[team.teamId]?.[
                                    member.email
                                    ] || false
                                  }
                                  onChange={() =>
                                    handleCheckboxChange(
                                      team.teamId,
                                      member.email
                                    )
                                  }
                                  className="w-4 h-4  rounded border-gray-300 focus:ring-lendingButton"
                                  style={{ color: `${organizationState[0]?.primaryColor}` }}
                                />
                                <label
                                  htmlFor={`member-${team.teamId}-${member.email}`}
                                  className="text-sm text-gray-600"
                                >
                                  {member.email}
                                </label>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="space-y-2">
                      {activeMembers.map((member) => (
                        <div
                          key={member.email}
                          className="flex items-center space-x-2 p-2 hover:bg-gray-50 rounded-md transition-colors duration-200"
                        >
                          <input
                            type="checkbox"
                            id={`org-member-${member.email}`}
                            checked={selectedItems[member.email] || false}
                            onChange={() =>
                              handleCheckboxChange(member.email)
                            }
                            className="w-5 h-5  rounded border-gray-300 focus:ring-lendingButton"
                            style={{ color: `${organizationState[0]?.primaryColor}` }}
                          />
                          <label
                            htmlFor={`org-member-${member.email}`}
                            className="text-sm text-gray-700"
                          >
                            {member.name} ( {member.email} )
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="p-6 border-t">
                  <button
                    onClick={shareFolder}
                    type="submit"
                    className={`w-full py-2 px-4 rounded-md font-medium transition-colors duration-200 ${isAnySelected()
                      ? ""
                      : "bg-gray-300 cursor-not-allowed"
                      }`}
                    style={{ backgroundColor: `${isAnySelected() ? organizationState[0]?.primaryColor : ""}` , color: `${organizationState[0]?.secondaryColor}` }}
                    disabled={!isAnySelected()}
                  >
                    Share Folder
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="p-6 border-b">
                  <div className="flex items-center justify-between">
                    <div className="h-8 bg-gray-200 rounded w-1/2 animate-pulse"></div>
                    <div className="w-8 h-8 bg-gray-200 rounded-full animate-pulse"></div>
                  </div>
                </div>
                <div className="flex p-4 space-x-4">
                  <div className="flex-1 h-10 bg-gray-200 rounded-md animate-pulse"></div>
                  <div className="flex-1 h-10 bg-gray-200 rounded-md animate-pulse"></div>
                </div>
                <div className="p-6 flex-grow overflow-y-auto">
                  {[1, 2, 3].map((item) => (
                    <div key={item} className="mb-4 border rounded-lg p-4">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <div className="w-5 h-5 bg-gray-200 rounded animate-pulse"></div>
                          <div className="h-6 bg-gray-200 rounded w-24 animate-pulse"></div>
                        </div>
                        <div className="w-8 h-8 bg-gray-200 rounded-full animate-pulse"></div>
                      </div>
                      <div className="mt-2 pl-6 space-y-2">
                        {[1, 2].map((subItem) => (
                          <div
                            key={subItem}
                            className="flex items-center space-x-2"
                          >
                            <div className="w-4 h-4 bg-gray-200 rounded animate-pulse"></div>
                            <div className="h-4 bg-gray-200 rounded w-32 animate-pulse"></div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="p-6 border-t">
                  <div className="w-full h-10 bg-gray-200 rounded-md animate-pulse"></div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <TeamModal teamModal={teamModal} setTeamModal={setTeamModal} />
    </>
  );
}

export default ShareFolderModal;

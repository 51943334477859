import { useEffect, useRef, useState } from "react";
import apiClient from "../../utils/axiosInstance";
import { AiOutlineDownload } from "react-icons/ai";
import { useAppDispatch, useAppSelector } from "../../store";
import { Icon } from "@iconify/react/dist/iconify.js";
import chinaUnion from "../../assets/chinaUnion.png";
import americanExp from "../../assets/americanExpress.png";
import discover from "../../assets/discover.png";
import diners from "../../assets/dinerclub.png";
import eftpos from "../../assets/EFTPOS.png";
import jcb from "../../assets/jcb.png";
import mastercard from "../../assets/master-card.png";
import visa from "../../assets/visa.png";
import { toast } from "react-toastify";
import { set } from "lodash";
import { PuffLoader } from "react-spinners";

const Subscription = ({ userDetailsState, org }) => {

    const [stripeData, setStripeData] = useState([]);
    const [viewMoreCards, setViewMoreCards] = useState(false);
    const [invoiceHistory, setInvoiceHistory] = useState([]);
    const [counts, setCounts] = useState(0);
    const [offset, setOffset] = useState(0);
    const [loader1, setLoader1] = useState(false);
    const [loader2, setLoader2] = useState(false);

    const { organizationState } = useAppSelector((state) => ({
      organizationState: state.organization.organization,
    }));
  


    const fetchInvoiceHistory = async () => {
        try {
            // if(offset === 0){
                setLoader2(true);
            // }
            const response = await apiClient().post(`/stripe/invoice?limit=10&offset=${offset}`);
            if (response.status === 200) {
                setOffset(offset + 10);
                setInvoiceHistory([...invoiceHistory, ...response.data.invoice_list]);
                setCounts(response.data.invoice_list.length);
            }
        } catch (error) {
            console.log("error getting card details", error);
            toast.error(`${error}`);
        }
        setLoader2(false);
    }

    useEffect(() => {
        fetchInvoiceHistory();
    }, []);

    return (
        <>
            <div className="account-table w-full overflow-y-auto">

                {/* <div className="w-full">
                    <h2 className="text-lg font-bold mb-4">Saved Cards</h2>
                    <div className="max-h-[300px] overflow-y-auto">
                        <table className="min-w-full mb-2" >
                            <thead className="sticky top-0 bg-white">
                                <tr className="flex items-center justify-between">
                                    <th className="px-4 py-2 text-left  text-sm font-medium text-gray-600 w-[33%]">Card Name</th>
                                    <th className="px-4 py-2 text-center  text-sm font-medium text-gray-600 w-[33%]">Card Number</th>
                                    <th className="px-4 py-2 text-center  text-sm font-medium text-gray-600 w-[33%]">Expiry</th>
                                </tr>
                            </thead>
                            <tbody>
                                { loader1 ? <>
                                    <tr className="border-t h-[50px] flex items-center justify-between">
                                        <td className="px-4 py-2 text-center flex items-center w-[33%]" >
                                            <div className=" bg-gray-200 rounded animate-pulse mr-2 w-16 h-[40px]"></div>
                                        </td>
                                        <td className="px-4 py-2 text-center text-sm w-[33%] flex justify-center">
                                            <div className="bg-gray-200 rounded animate-pulse mr-2 w-[105px] h-[18px]"></div>
                                        </td>
                                        <td className="px-4 py-2 text-center text-sm w-[33%] flex justify-center">
                                            <div className="bg-gray-200 rounded animate-pulse mr-2 w-[50px] h-[18px]"></div>
                                        </td>
                                    </tr>
                                </> : viewMoreCards ? stripeData?.map(renderCard) : stripeData?.slice(0, 1).map(renderCard)}
                            </tbody>
                        </table>
                    </div>
                    {!viewMoreCards && stripeData?.length > 1 ? (
                        <div onClick={() => setViewMoreCards(true)} className="mt-4 flex text-sm cursor-pointer text-gray-500 hover:underline hover:text-gray-700 items-center justify-center"> + {stripeData.length - 1} more cards</div>
                    ) : stripeData?.length !== 1 ?
                        <div onClick={() => setViewMoreCards(false)} className="mt-4 flex text-sm cursor-pointer items-center justify-center text-gray-500 hover:underline hover:text-gray-700">Show Less</div>
                        : null}
                </div> */}

                <div className=" w-full ">
                    <h2 className="text-lg font-bold mb-4">Invoice History</h2>
                    <div className=" overflow-y-auto">
                        <table className="min-w-full  mb-2 ">
                            <thead className="sticky top-0 bg-white">
                                <tr className="flex items-center justify-between">
                                    <th className="px-4 py-2 text-left text-sm font-medium text-gray-600 w-[25%]">Date</th>
                                    <th className="px-4 py-2 text-center text-sm font-medium text-gray-600 w-[25%]">Amount</th>
                                    <th className="px-4 py-2 text-center text-sm font-medium text-gray-600 w-[25%]">Status</th>
                                    <th className="px-4 py-2 text-center text-sm font-medium text-gray-600 w-[25%]">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loader2 ? (
                                    <>
                                        <tr className="border-t h-[50px] flex items-center justify-between">
                                            <td className="px-4 py-2 text-left text-sm w-[25%]">
                                                <div className="bg-gray-200 rounded animate-pulse mr-2 w-[80%] h-[18px]"></div>
                                            </td>
                                            <td className="px-4 py-2 text-center text-sm w-[25%] flex justify-center">
                                                <div className="bg-gray-200 rounded animate-pulse mr-2 w-[80%] h-[18px]"></div>
                                            </td>
                                            <td className="px-4 py-2 text-center text-sm w-[25%] flex justify-center">
                                                <div className="bg-gray-200 rounded animate-pulse mr-2 w-[80%] h-[18px]"></div>
                                            </td>
                                            <td className="px-4 py-2 flex items-center justify-center text-xl w-[25%]">
                                                <div className="bg-gray-200 rounded animate-pulse mr-2 w-[30%] h-[18px]"></div>
                                            </td>
                                        </tr>
                                    </>
                                ) : invoiceHistory.length === 0 ? (
                                    <tr>
                                        <td colSpan="4" className="text-center py-4">
                                            No Invoice History
                                        </td>
                                    </tr>
                                ) : (
                                    <RenderInvocieHistory invoiceHistory={invoiceHistory} />
                                )}
                            </tbody>

                        </table>
                    </div>
                    {counts >= 10 && (
                        <div
                            className="mt-4 flex text-sm cursor-pointer text-gray-500 hover:underline hover:text-gray-700 items-center justify-center"
                            onClick={() => {
                                if (!loader2) {
                                    fetchInvoiceHistory()
                                }
                            }}
                        >
                            {loader2 ? (
                                <div className="spinner flex items-center justify-center">
                                    <PuffLoader
                                        color={userDetailsState?.primaryColor || organizationState[0]?.primaryColor}
                                        cssOverride={null}
                                        loading
                                        size={40}
                                    />
                                   
                                </div>) : ("Load More")}
                        </div>
                    )}
                </div>
            </div>

        </>
    )
}

const renderCard = (card) => {

    const cardLogos = [
        {
            brand: "American Express",
            logo: americanExp,
        },
        {
            brand: "China UnionPay",
            logo: chinaUnion,
        },
        {
            brand: "Discover",
            logo: discover,
        },
        {
            brand: "Diners Club",
            logo: diners,
        },
        {
            brand: "eftpos Australia",
            logo: eftpos,
        },
        {
            brand: "Japan Credit Bureau",
            logo: jcb,
        },
        {
            brand: "Mastercard",
            logo: mastercard,
        },
        {
            brand: "Visa",
            logo: visa,
        },
    ];

    const logo = cardLogos.find((logo) => logo.brand.toLowerCase() === card.brand.toLowerCase())?.logo;

    return (
        <tr key={card.id} className="border-t h-[50px] flex items-center justify-between">
            <td className="px-4 py-2 text-center flex items-center w-[33%]" >
                {logo && (
                    <img
                        src={logo}
                        alt={card.brand}
                        className="w-16 h-[40px] object-contain mr-2"
                    />
                )}
            </td>
            <td className="px-4 py-2 text-center text-sm w-[33%]">•••• {card.last4}</td>
            <td className="px-4 py-2 text-center text-sm w-[33%]">
                {`${card.exp_month}/${card.exp_year.toString().slice(-2)}`}
            </td>
        </tr>
    );
};

const RenderInvocieHistory = ({ invoiceHistory }) => {
    const convertTimeStamp = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleString();
    }
    return <>
        {invoiceHistory.map((invoice) => (
            <tr key={invoice.invoice_id} className="border-t h-[50px] flex items-center justify-between">
                <td className="px-4 py-2 text-left text-sm w-[25%]">
                    {convertTimeStamp(invoice.created)}
                </td>
                <td className="px-4 py-2 text-center text-sm w-[25%]">
                ${(invoice.amount / 100).toFixed(2)}
                </td>
                <td className="px-4 py-2 text-center text-sm w-[25%]">
                    {invoice.status}
                </td>
                <td className="px-4 py-2 flex items-center justify-center text-xl w-[25%] ">
                    <a href={invoice?.invoice_link} target="_blank" className="hover:text-blue-900" >
                        <AiOutlineDownload />
                    </a>
                </td>
            </tr>
        ))}
    </>
}

export default Subscription;
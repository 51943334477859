import Spinner from "react-spinner-material";

function SpinnerLoading({ isLoading }) {
    return (
        <>
            {isLoading && (
                <div className="loadingSpinner">
                    <Spinner size={100} color={"#EFEFEF"} visible={isLoading} />
                </div>
            )}
        </>
    );
}

export default SpinnerLoading;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import { changeModel, updateKey } from '../../store/aiModelSlice';
import { toast } from 'react-toastify';
import apiClient from '../../utils/axiosInstance';

const Documnetsettings = () => {
    const { modelState, userDetails } = useSelector((state) => ({
        modelState: state.aiModels.aiModels,
        userDetails: state.auth.userDetails,
    }));
    const openaiProvider = modelState?.providers?.find(provider => provider.provider_name === "pinecone");
    const dispatch = useDispatch();
    const [modelProvider, setModelProvider] = useState("")
    useEffect(() => {
        setModelProvider(modelState?.providers)
    }, [])
    const [editableProviderKey, setEditableProviderKey] = useState('');
    const [isKeyValid, setIsKeyValid] = useState(true);
    const [showKeys, setShowKeys] = useState({});
    const [editingProviderId, setEditingProviderId] = useState(null);

    const toggleShowKey = (providerId) => {
        setShowKeys(prev => ({ ...prev, [providerId]: !prev[providerId] }));
    };

    const handleModelChange = async (providerId, newModel) => {
        const res = await apiClient().post("/change-model", {
            providerId,
            model: newModel
        });
        dispatch(changeModel({ providerId, models: res.data.models }));
    };

    const handleEditClick = (openaiProvider) => {
        setEditingProviderId(openaiProvider.modelId);
        setEditableProviderKey(openaiProvider.provider_key);
        setIsKeyValid(true);
    };

    const verifyGeminiApiKey = async (apiKey) => {
        const API_VERSION = 'v1';
        const apiUrl = `https://generativelanguage.googleapis.com/${API_VERSION}/models?key=${apiKey}`;
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            muteHttpExceptions: true,
        });
        return response.status === 200;
    };

    const verifyOpenaiApiKey = async (apiKey) => {
        const apiUrl = `https://api.openai.com/v1/models`;
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${apiKey}` },
            muteHttpExceptions: true,
        });
        return response.status === 200;
    };

    const handleSaveClick = async () => {
        if (editableProviderKey === "" || editableProviderKey == null) {
            toast.error("Provider Key Should not be empty");
            return;
        }
        const provider = modelState.providers.find(p => p.modelId === editingProviderId);
        if (!provider) return;

        if (provider.provider_name === 'openai') {
            const isValid = await verifyOpenaiApiKey(editableProviderKey);
            setIsKeyValid(isValid);
            if (!isValid) {
                toast.error('Invalid OpenAI API key');
                return;
            }
        } else if (provider.provider_name === 'gemini') {
            const isValid = await verifyGeminiApiKey(editableProviderKey);
            setIsKeyValid(isValid);
            if (!isValid) {
                toast.error('Invalid Gemini API key');
                return;
            }
        }

        if (editableProviderKey !== provider.provider_key) {
            try {
                await apiClient().put(`/provider/${editingProviderId}`, {
                    providerKey: editableProviderKey,
                });
                dispatch(updateKey({ providerId: editingProviderId, key: editableProviderKey }));
            } catch (error) {
                toast.error(`Error updating provider key: ${error}`);
            }
        }
        setEditingProviderId(null);
    };

    const handleKeyChange = (e) => {
        setEditableProviderKey(e.target.value);
        setIsKeyValid(true);
    };

    const showModelsForProvider = (providerName) => {
        return ['openai', 'gemini', 'anthropic'].includes(providerName.toLowerCase());
    };

    const getCurrentModelForProvider = (providerId) => {
        const models = modelState.models[providerId];
        if (!models) return null;
        return models.find(model => model.currentUsed)?.model || null;
    };
    return (
        <div className="overflow-x-auto mt-2 rounded-xl bg-white border border-borderColor p-5">
            <div className="min-w-full flex ">
                <div className="w-[20%] flex flex-col gap-3">
                    <div className={`px-4 py-2  `}>Pincone</div>

                    {/* {(userDetails?.role === "admin" || userDetails?.role === "super admin") && (
                        <div className="px-4 py-2 ">Api Key</div>
                    )} */}
                </div>

                <div className="w-[80%] flex items-center justify-end ">
                    {
                        openaiProvider && <div className="flex flex-col gap-3 w-full">
                            {/* <div className={`px-4 py-2 text-center`}>
                                {openaiProvider?.provider_name}
                            </div> */}
                            <div className={`px-4  text-center flex items-center  border border-slate-200 rounded-[20px] w-full`}>
                                <div className='border-t-0 border-b-0 border-l-0 border-r border-slate-200  me-2  py-2 w-[90%]'>
                                    {editingProviderId === openaiProvider.modelId ? (
                                        <input
                                            type="text"
                                            value={editableProviderKey}
                                            onChange={handleKeyChange}
                                            className={` p-0 border-t-0 border-b-0 border-l-0 border-r-1 border-slate-200 me-2 pe-2 py-2 w-full max-w-[150px] overflow-x-auto ${!isKeyValid ? 'border-red-500' : ''}`}
                                        />
                                    ) : (
                                        <div className="max-w-full overflow-x-auto m-auto whitespace-nowrap">
                                            {showKeys[openaiProvider?.modelId] ? openaiProvider?.provider_key : '*'.repeat(36)}
                                        </div>
                                    )}
                                </div>
                                {(userDetails?.role === "admin" || userDetails?.role === "super admin") && (
                                    <>

                                        <div className={`text-center  flex items-center me-1 pe-1 border-r border-slate-200 h-full w-[5%] ${openaiProvider?.provider_name === 'openai' ? 'w-1/4' : 'w-1/3'}`}>
                                            <button onClick={() => toggleShowKey(openaiProvider?.modelId)} className="mr-1 ">
                                                <Icon icon={showKeys[openaiProvider?.modelId] ? "mdi:eye-off" : "mdi:eye"} width="1.2em" height="1.2em" />
                                            </button>
                                        </div>
                                        <div className={`text-center  flex items-center w-[5%] ${openaiProvider?.provider_name === 'openai' ? 'w-1/4' : 'w-1/3'}`}>
                                            {editingProviderId === openaiProvider?.modelId ? (
                                                <button onClick={handleSaveClick}>
                                                    <Icon icon="mdi:check" width="1.2em" height="1.2em" />
                                                </button>
                                            ) : (
                                                <button onClick={() => handleEditClick(openaiProvider)}>
                                                    <Icon icon="mdi:pencil" width="1.2em" height="1.2em" />
                                                </button>
                                            )}
                                        </div>
                                    </>

                                )}
                            </div>


                        </div>
                    }
                </div>
            </div>
        </div>
    )
}


export default Documnetsettings;
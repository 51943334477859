import React from "react";
import quoromlogo from "../../assets/logo-quorom.png";
import cospcaelogo from "../../assets/logo-cospace.png"

function Footer() {

  return (
    <div className="footer bg-[#D3CCF8] py-20 flex flex-col gap-8 items-center">
      <div className="logo ">
        <img src={cospcaelogo} alt="" className="max-w-[240px]" />
      </div>
      <div className="links flex items-center gap-4">
        <a href="/terms-of-service" className="text-sm">
          Terms of Service
        </a>
        <a href="/privacy-policy" className="text-sm">
          Privacy Policy
        </a>
        <a href="/contact" className="text-sm">
          Contact
        </a>
      </div>
      <div className="copyright">
        <p className="text-sm text-[#7E7E7E]">Copyright © 2024 I Cospace.ai</p>
      </div>
    </div>
  );
}
export default Footer;

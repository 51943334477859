import AIModelsSection from "../AIModelsSection";


const Aiseeting = ({org}) => {
    return (
        <>
        <div>
            <AIModelsSection organization={org}/>
        </div>
        </>
    )
}


export default Aiseeting;
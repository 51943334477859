import { useEffect, useState } from "react";
import { getTeams } from "../../store/teamSlice";
import AllMembersModal from "./AllMemberModal";
import { useAppDispatch, useAppSelector } from "../../store";

const MemberSkeleton = () => (
  <div className="flex items-center">
    {[...Array(3)].map((_, index) => (
      <div key={index} className="-ml-2 first:ml-0">
        <div className="w-8 h-8 rounded-full bg-gray-300 animate-pulse"></div>
      </div>
    ))}
  </div>
);

function Members({ teamIds, setInviteModule, isAnotherspace = false, team = {}, setIsAnotherspace, setSelectedTeam }) {
  const dispatch = useAppDispatch();
  const [showAllMembers, setShowAllMembers] = useState(false);
  const { teamState, teamStatus, organizationState } = useAppSelector((state) => ({
    teamState: state?.teams?.teams,
    teamStatus: state.teams.teamStatus,
    organizationState: state.organization.organization,
  }));

  useEffect(() => {
    if (!teamState?.length > 0 && !isAnotherspace) {
      dispatch(getTeams());
    }
  }, []);

  const relevantTeams = teamState?.filter((team) => teamIds?.includes(team.teamId)) || [];

  const allMembers = isAnotherspace ? team.members : relevantTeams.flatMap((team) => team.members || []);

  const uniqueMembers = Array.from(
    new Map(allMembers.map((member) => [member.email, member])).values()
  );

  const displayedMembers = uniqueMembers;
  const remainingCount = uniqueMembers.length - displayedMembers.length;

  if (teamStatus === "loading") {
    return (
      <div className={`flex-1 ml-60 md:mt-0`}>
        <div className="flex items-center justify-between border-borderColor">
          <MemberSkeleton />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex items-center justify-between border-borderColor cursor-pointer">
        <div
          className="flex items-center border border-borderColor rounded-md"
          onClick={() => {
            setShowAllMembers(true);
          }}
        >
          {displayedMembers.slice(0, 3).map((member, index) => { 
            
            return (
            <div key={member.email} className="-ml-3 first:ml-0 relative first:me-0 last:me-0 me-1 p-1">
              <div
                className="w-6 h-6 rounded-md  flex items-center justify-center text-white font-semibold"
              style={{
                backgroundColor: organizationState[0]?.primaryColor,
              }}
              >
                {member?.userPictureUrl ? (
                  <img
                    src={member?.userPictureUrl}
                    alt={member?.name}
                    className="w-full h-full rounded-md object-cover"
                  />
                ) : (
                  member.email[0].toUpperCase()
                )
                }
              </div>
              <div
                className={`absolute -top-0 right-[0.4rem] w-2 h-2 rounded-full border border-white ${member?.user_status === "active" ? "bg-green-500" : "bg-gray-400"
                  }`}
              ></div>
            </div>
          ) })}
     {displayedMembers?.length > 0 &&
            <div className=" relative">
              <div
                className="w-6 h-6 flex items-center justify-center text-black text-sm font-medium"
              >
                {displayedMembers.length}
              </div>
            </div>
}

          {remainingCount > 0 && (
            <div
              className="-ml-2 w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center text-gray-600 font-semibold cursor-pointer"
              onClick={() => setShowAllMembers(true)}
            >
              +{remainingCount}
            </div>
          )}
        </div>
      </div>
      {teamIds?.length > 0 && (
        <AllMembersModal
          isAnotherspace={isAnotherspace}
          team={team}
          setIsAnotherspace={setIsAnotherspace}
          teamId={teamIds[0]}
          members={uniqueMembers}
          showAllMembers={showAllMembers}
          setShowAllMembers={setShowAllMembers}
          setInviteModule={setInviteModule}
        />
      )}
    </>
  );
}

export default Members;

import React, { useState, useEffect } from 'react';
import apiClient from '../utils/axiosInstance';
import { Icon } from '@iconify/react/dist/iconify.js';

const LinkPreview = ({ url, onClose }) => {
    const [preview, setPreview] = useState(null);

    useEffect(() => {
        const fetchPreview = async () => {
            try {
                const response = await apiClient().post("/link-preview", { url });
                setPreview(response.data);
            } catch (error) {
                console.error('Error fetching link preview:', error);
            }
        };

        if (url) {
            fetchPreview();
        }
    }, [url]);

    if (!preview) return null;

    return (
        <div className="border rounded-md p-3 m-2 flex flex-col relative max-w-60">
            <button
                onClick={() => onClose(url)}
                className="absolute top-1 right-1 text-gray-500 hover:text-gray-700"
                aria-label="Close preview"
            >
                <Icon icon="line-md:menu-to-close-transition" className="w-4 h-4" />
            </button>
            {/* {preview.favicon && (
                <img src={preview.favicon} alt={preview.title} className="w-full h-20 object-cover mb-2 rounded-md" />
            )} */}
            <h3 className="font-bold text-sm mb-1 truncate">{preview.title}</h3>
            <p className="text-xs text-gray-600 mb-1 truncate">{preview.description}</p>
            <a href={url} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-xs hover:underline truncate">
                {url}
            </a>
        </div>
    );
};

const LinkPreviewGrid = ({ urls, setUrls, closedLinks, setClosedLinks }) => {

    const handleClose = (urlToClose) => {
        setClosedLinks(prevClosedLinks => [...prevClosedLinks, urlToClose]);
        setUrls(prevUrls => prevUrls.filter(url => url !== urlToClose));
    };

    const filteredUrls = urls.filter(url => !closedLinks.includes(url));

    if (filteredUrls.length === 0) return null;

    return (
        <div className="w-100 flex items-center flex-wrap h-[102px] overflow-auto">
            {filteredUrls.map((url, index) => (
                <div key={index} className='lg:w-1/3 md:w-1/2  w-full '>
                    <LinkPreview url={url} onClose={handleClose} />
                </div>
            ))}
        </div>
    );
};

export default LinkPreviewGrid;
import Sidebar from "../Components/Sidebar";
import DashboardSidebar from "./DashboardSidebar";
import { useLocation } from "react-router-dom";
import Documents from "../Components/Documents/Documents";
import { useAppDispatch, useAppSelector } from "../store";
import { useEffect } from "react";
import { getFolders } from "../store/DocumentSlice";
import { Icon } from "@iconify/react/dist/iconify.js";

function Folder() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const folderId = queryParams.get('id');
  const dispatch = useAppDispatch();
  const { folderState, organizationState, userDetailsState } = useAppSelector((state) => ({
    folderState: state.document.folders,
    organizationState: state.organization.organization,
    userDetailsState: state.auth.userDetails
  }));

  useEffect(() => {
    if (folderState.length === 0 || folderState.folders.length === 0) {
      dispatch(getFolders());
    }
  }, []);

  const folder = folderState?.folders?.find((folder) => folder.folderId === folderId);

  return (
    <>
      {folderState?.folders?.length === 0 ? <>
        <div className="flex flex-col items-center justify-center w-full header-height text-gray-500">
          <Icon icon="ph:folders" width="100" height="100" />
          <h2 className="mt-4 text-xl font-semibold">No Folders Yet!</h2>
        </div>
      </> : <>
        <div
          className={`bg-backgroundColor  header-height flex justify-center items-center w-full md:px-0 px-2 my-pb-20`}
        >
          <div className="rounded-lg w-[100%] md:w-full lg:w-full">
            <div className="relative">
              <div className="header-height overflow-y-hidden lg:h-[100%] xl:h-[100vh] w-full">
                {folder && <Documents folder={folder} />}
               
               
                
              </div>
            </div>
          </div>
        </div>
      </>}
    </>
  );
}

export default Folder;
import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useAppDispatch } from "../store/index";
import SpinnerLoading from "../Components/SpinnerLoading";
import { reportChat } from "../store/finChat";
import apiClient from "../utils/axiosInstance";
import { reportDocumentChat } from "../store/DocumentSlice";

const report = [
  {
    name: "Inaccurate",
    icon: "gravity-ui:octagon-xmark",
  },
  {
    name: "Out of date",
    icon: "la:scroll",
  },
  {
    name: " Too short",
    icon: "fa6-solid:arrow-down-short-wide",
  },
  {
    name: "Too long",
    icon: "fa6-solid:arrow-up-short-wide",
  },
  {
    name: "Harmful or offensive",
    icon: "icon-park-outline:worried-face",
  },
  ,
  {
    name: "No helpful",
    icon: "ph:seal-question",
  },
];

function ReportModal({ isReportModal, setIsReportModal, messageId, type }) {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [reportFeedBack, setReportFeedBack] = useState();
  const [selectedButton, setSelectedButton] = useState(null);

  const closeModal = () => {
    setIsReportModal(false);
  };

  const handleButtonClick = (name) => {
    setSelectedButton(name);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (type === "doc-chat") {
        const body = {
          action: "report",
          messageId: messageId,
          label: selectedButton,
          reason: reportFeedBack,
          createdAt: new Date().toISOString(),
        };
        await apiClient().post(`/chat`, body);
        dispatch(reportDocumentChat(messageId));
        setIsReportModal(false);
      } else {
        const body = {
          action: "report",
          messageId: messageId,
          label: selectedButton,
          reason: reportFeedBack,
        };
        await apiClient().post(`/chat`, body);
        dispatch(reportChat(messageId));
        setIsReportModal(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <SpinnerLoading isLoading={isLoading} />}
      {isReportModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="rounded-lg w-[350px] md:w-[600px] bg-white">
            <div className="p-4 md:p-4 flex items-center justify-between">
              <p className="text-color text-3xl font-medium text-center ">
                Report
              </p>
              <button
                onClick={closeModal}
                className="flex items-center p-2  text-secondary rounded-lg font-normal text-lg  hover:bg-gray-100 "
              >
                <Icon icon="iconamoon:close-light" className="text-2xl" />
              </button>
            </div>
            <hr className="h-px w-full md:w-[37rem]  m-auto bg-borderColor border-0"></hr>
            <div className="p-4 md:p-4 ">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                {report.map((data, index) => {
                  return (
                    <div key={index}>
                      <button
                        className={`flex items-center w-full text-[#13343B] border-2 font-medium py-2 px-4 rounded ${selectedButton === data.name && "text-[#20808D]"
                          }`}
                        onClick={() => handleButtonClick(data.name)}
                      >
                        <Icon
                          icon={data.icon}
                          fontSize={20}
                          className={`mr-2 text-[#13343B] ${selectedButton === data.name && "text-[#20808D]"
                            } `}
                        />
                        <p className="text-md font-medium ">{data.name}</p>
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="p-4 md:p-4">
              <div>
                <p className="text-md text-[#13343B] ">
                  How can the response be improved?
                  <span className="text-md text-gray-900 ml-2">(optional)</span>
                </p>
                <input
                  type="text"
                  id="last_name"
                  value={reportFeedBack}
                  onChange={(e) => setReportFeedBack(e.target.value)}
                  className={`bg-gray-50 border border-gray-300  text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full md:w-full mt-2 p-2.5  
                  `}
                  placeholder="Your feedback"
                  required
                />
              </div>
            </div>
            <hr className="h-px w-full md:w-[37rem]  m-auto bg-borderColor border-0 "></hr>
            <div className="flex items-center md:px-2 pb-3 pt-3 float-right	">
              <button
                onClick={closeModal}
                className="flex items-center px-4 py-2 text-[#13343B]  rounded-md font-normal text-lg  hover:text-gray-400 bg-gray-100 group"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className="flex items-center font-sans px-4 ml-4 mr-2 py-2 text-white bg-[#20808D] rounded-md font-normal text-lg  hover:bg-[#20808D]   group"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ReportModal;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import apiClient from "../../utils/axiosInstance";

function EmailVerify() {
  const navigate = useNavigate();
  const params = useParams();
  const { token } = params;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        if (!token) {
          throw new Error("Invalid or missing token");
        }
        const response = await apiClient().get(`/verification-email/${token}`);

        if (response.status === 200) {
          navigate(`/create-profile/${token}`);
        }
      } catch (error) {
        toast.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    verifyEmail();
  }, [token]);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Verifying Your Email...
        </h2>
        {isLoading && (
          <p className="mt-2 text-center text-sm text-gray-600">
            Please wait while we verify your email.
          </p>
        )}
      </div>
    </div>
  );
}

export default EmailVerify;

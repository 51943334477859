import React from "react";
import { useNavigate } from "react-router-dom";

function VerificationSuccess() {
  const navigate = useNavigate();

  const handleGoToLogin = () => {
    navigate("/signin", {
      state: {
        toLogin: true,
      },
    });
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Email Verified Successfully
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Your email address has been successfully verified.
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="text-center">
            <svg
              className="mx-auto h-12 w-12 text-green-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <p className="mt-2 text-sm text-gray-600">
              Thank you for verifying your email address. You can now login and
              access your dashboard.
            </p>
            <button
              onClick={handleGoToLogin}
              className="mt-4 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Go to Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerificationSuccess;

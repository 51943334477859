import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useAppDispatch, useAppSelector } from "../store";
import { getFolders } from "../store/DocumentSlice";
import SpinnerLoading from "./SpinnerLoading";
import { FilesPreview, ImageUpload } from "./Helper";
import { EditorState, Modifier } from "draft-js";
import apiClient from "../utils/axiosInstance";
import { addNewChat, getUserChatsWithinLimit } from "../store/finChat";
import useAuth from "../utils/useAuth";
import posthog from "posthog-js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { useSocket } from "../context/SocketProvider";
import WebChatModel from "./WebChatModel";
import LinkPreviewGrid from "./LinkPreviewGrid";
import { Sources } from "../utils/static";

const insertMention = (editorState, mention) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  const mentionText = `${mention.text} `;
  const contentStateWithEntity = contentState.createEntity(
    "MENTION",
    "IMMUTABLE",
    { mention }
  );

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const contentStateWithMention = Modifier.replaceText(
    contentState,
    selectionState,
    mentionText,
    null,
    entityKey
  );

  const newEditorState = EditorState.push(
    editorState,
    contentStateWithMention,
    "insert-characters"
  );

  return EditorState.forceSelection(
    newEditorState,
    contentStateWithMention.getSelectionAfter()
  );
};

const MessageEditor = ({
  selectedFiles,
  organization,
  isAnotherspace = false,
  setSelectedFiles,
  uploadingFiles,
  setUploadingFiles,
  tags,
  setTags,
  handleSendMessage,
  isApiCalling,
  setIsApiCalling,
  chatApiCalling,
  setChatApiCalling,
  isLoading,
  mentionState,
  setMentionState,
  taggedFolders,
  setTaggedFolders,
  editorState,
  setEditorState,
  sharedFolders,
  tab,
  isReplying = false,
}) => {
  const { user } = useAuth();
  const { socket } = useSocket();
  const selectedFilesRef = useRef(selectedFiles);
  selectedFilesRef.current = selectedFiles;
  const { folderState, status, chats, userDetailsState, isPlanActive } =
    useAppSelector((state) => ({
      chats: state.finChat.chatWithLimit,
      userDetailsState: state.auth.userDetails,
      folderState: state.document.folders,
      status: state.document.folderStatus,
      isPlanActive: state.auth.isPlanActive,
    }));
  const navigate = useNavigate();
  let folders = [];
  const [detectedLinks, setDetectedLinks] = useState([]);
  const [closedLinks, setClosedLinks] = useState([]);
  const [selectedOption, setSelectedOption] = useState(["auto"]);
  const [isOpen, setIsOpen] = useState(false);
  const [link, setLink] = useState({ isLink: 0, url: "" });
  const [isWebChatOpen, setIsWebChatOpen] = useState(false);

  const { organizationState } = useAppSelector((state) => ({
    organizationState: state.organization.organization,
  }));

  useEffect(() => {
    if (selectedOption === "webchat") {
      setIsOpen(true);
    }
  }, [selectedOption]);

  const mapFolders = (folderArray) =>
    folderArray?.map((folder) => ({
      folderName: folder.folderName,
      folderId: folder.folderId,
    })) || [];

  folders = (() => {
    switch (tab) {
      case "chat":
        return tags.length > 0
          ? mapFolders(sharedFolders)
          : mapFolders(folderState?.folders);
      case "thread":
        if (tags?.length === 0) return mapFolders(folderState?.folders);
        return sharedFolders?.length > 0 ? mapFolders(sharedFolders) : [];
      default:
        return sharedFolders?.length > 0 ? mapFolders(sharedFolders) : [];
    }
  })();

  const dispatch = useAppDispatch();

  useEffect(() => {
    setEditorState((prevState) => {
      const contentState = prevState.getCurrentContent();
      const selection = prevState.getSelection();
      const mentionEntity = contentState.createEntity("MENTION", "IMMUTABLE", {
        mention: { name: `@${organization?.chatBotName || "chatbot"}` },
      });
      const entityKey = mentionEntity.getLastCreatedEntityKey();
      const mentionText = `@${organization?.chatBotName}`;
      const contentStateWithEntity = Modifier.replaceText(
        contentState,
        selection.merge({
          anchorOffset: 0,
          focusOffset: contentState.getPlainText().length,
        }),
        null,
        entityKey
      );

      const newEditorState = EditorState.push(
        prevState,
        contentStateWithEntity,
        "insert-characters"
      );
      return EditorState.moveFocusToEnd(newEditorState);
    });
  }, [organization]);

  useEffect(() => {
    if (!folderState?.folders?.length > 0) {
      dispatch(getFolders());
    }
  }, [dispatch, folderState?.folders?.length]);

  const handleChange = (newEditorState) => {
    setEditorState(newEditorState);
    const content = newEditorState.getCurrentContent();
    const rawText = content.getPlainText();
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const links = rawText.match(urlRegex) || [];
    setDetectedLinks(links);
  };

  const handleReturn = (e) => {
    if (!isPlanActive) {
      toast.error("Please upgrade your plan to use this feature");
      return;
    }
    setDetectedLinks([]);
    if (isAnotherspace) {
      return "handled";
    }
    const isEnterPress = e && e.key === "Enter" && !e.shiftKey;
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const blockKey = selectionState.getStartKey();
    const block = contentState.getBlockForKey(blockKey);
    const textBeforeCursor = block
      .getText()
      .slice(0, selectionState.getStartOffset());

    const lastCharacter = textBeforeCursor.slice(-1);

    if (isEnterPress && lastCharacter === "@") {
      const mentionList = getSuggestions();
      if (mentionList.length > 0) {
        const firstMention = mentionList[0];
        const newEditorState = insertMention(editorState, firstMention);
        const contentState = newEditorState.getCurrentContent();
        const plainText = contentState.getPlainText();

        const newTaggedFolders = folders
          ?.filter((folder) => plainText.includes(`@${folder.folderName}`))
          .map((folder) => folder.folderId);

        setTaggedFolders(newTaggedFolders);
        setEditorState(newEditorState);
        return "handled";
      }
    } else if (isEnterPress) {
      console.log("isEnterPress");

      const contentState = editorState.getCurrentContent();
      const hasText = contentState.getPlainText().trim();
      const hasFiles = selectedFiles.length > 0;

      if (hasText.trim() || hasFiles) {
        const contentState = editorState.getCurrentContent();
        const plainText = contentState.getPlainText();
        const containsAskfinAI = plainText.includes(
          `@${organization?.chatBotName}`
        );
        const messageText = plainText
          .replace(`@${organization?.chatBotName}`, "")
          .trim();
        if (
          messageText.trim() !== "" &&
          tags?.length === 0 &&
          tab === "chat" &&
          taggedFolders.length === 0
        ) {
          askfinThread(messageText);
          setSelectedFiles([]);
          setEditorState(EditorState.createEmpty());
          return "handled";
        } else if (messageText.trim() !== "" && tab === "chat") {
          normalThread(messageText, hasFiles, containsAskfinAI);
          setSelectedFiles([]);
          setEditorState(EditorState.createEmpty());
          return "handled";
        } else if (messageText.trim() !== "" && tab !== "chat") {
          handleSendMessage(editorState);
          setSelectedFiles([]);
          setEditorState(EditorState.createEmpty());
          return "handled";
        }
      }
    }

    return "not-handled";
  };

  const askfinThread = async (messageText) => {
    try {
      setChatApiCalling(true);

      let body = {
        query: messageText.replace(`@${organization?.chatBotName}`, "").trim(),
        action: "chat",
        createdAt: new Date().toISOString(),
      };

      const response = await apiClient().post(`/chat`, body);
      dispatch(addNewChat(response.data));

      if (response && chats.length === 0 && user?.email) {
        posthog.identify(user?.email);
        posthog.capture("chat_started", { email: user?.email });
      }

      const threadId = response?.data?.threadId;
      if (threadId) {
        navigate(`/answer-search?id=${threadId}`);
      }
    } catch (error) {
      toast.error(error);
      console.log(error);
    } finally {
      setChatApiCalling(false);
    }
  };

  const normalThread = async (messageText, hasFiles, containsAskfinAI) => {
    try {
      setIsApiCalling(true);

      const taggedMembers = Object.values(
        tags
          .filter((tag) => tag.type === "member")
          .reduce((acc, tag) => {
            acc[tag.id] = tag;
            return acc;
          }, {})
      );
      const taggedTeams = Object.values(
        tags
          .filter((tag) => tag.type === "team")
          .reduce((acc, tag) => {
            acc[tag.id] = tag;
            return acc;
          }, {})
      );
      const tagged = [...taggedMembers, ...taggedTeams];
      const sharedWith = tagged.map((tag) => ({
        tagId: tag.id,
        accountType: tag.type,
      }));

      const messageType = hasFiles
        ? messageText
          ? "text-file"
          : "file"
        : "text";

      const payload = {
        threadId: uuidv4(),
        messageId: uuidv4(),
        isThread: true,
        message: messageText,
        tag: sharedWith,
        createdAt: new Date().toISOString(),
        messageType: messageType,
      };

      if (hasFiles) {
        payload.files = selectedFiles.map((file) => ({
          docS3Loc: file.docS3Loc,
          url: file.url,
          fileName: file.file.name,
        }));
      }

      const tempResponse = {
        threadId: payload.threadId,
        chatName: payload.message,
        createdAt: payload.createdAt,
        message: payload.message,
        senderId: user?.userId,
        senderName: userDetailsState?.name,
        senderProfile: userDetailsState?.userPictureUrl,
        tag: tagged.map((tag) => ({
          tagId: tag.id,
          accountType: tag.type,
          tagName: tag.name,
        })),
        receivers: tagged.map((tag) => ({
          tagId: tag.id,
          accountType: tag.type,
        })),
        messageId: payload.messageId,
        type: payload.type,
        messageType: payload.messageType,
        files: payload.files,
      };

      const tempThreadResponse = {
        ...tempResponse,
        type: payload.type,
      };

      if (containsAskfinAI) {
        tempResponse.isAiChat = true;
        tempResponse.query = payload.message;
        tempResponse.taggedFolders = taggedFolders;
      }

      navigate(`/thread?id=${tempResponse.threadId}`, {
        state: {
          chat: tempResponse,
        },
      });

      socket.emit("new_main_thread_created", {
        tag: tagged.map((tag) => ({
          tagId: tag.id,
          accountType: tag.type,
        })),
        thread: tempThreadResponse,
      });

      setEditorState(EditorState.createEmpty());
      dispatch(addNewChat(tempResponse));
      await apiClient().post("/thread", payload);
    } catch (error) {
      toast.error(error);
      console.log(error);
    } finally {
      setIsApiCalling(false);
      setTags([]);
    }
  };

  const handleMentionChange = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const plainText = contentState.getPlainText();
    const askFinTagged = plainText.includes(`@${organization?.chatBotName}`);

    const newTaggedFolders = folders
      ?.filter((folder) => plainText.includes(`@${folder.folderName}`))
      .map((folder) => folder.folderId);

    setTaggedFolders(newTaggedFolders);

    setMentionState((prev) => ({
      ...prev,
      showAskFin: !askFinTagged,
      askFinTagged: askFinTagged,
    }));

    getSuggestions(newTaggedFolders);
  };

  const getSuggestions = (currentTaggedFolders) => {
    if (mentionState.showAskFin) {
      return [
        { text: organization?.chatBotName, value: organization?.chatBotName },
      ];
    }
    const uniqueFolders = folders?.reduce((acc, current) => {
      const x = acc.find((item) => item.folderId === current.folderId);
      if (!x) {
        acc.push(current);
      }
      return acc;
    }, []);
    return uniqueFolders
      ?.filter((folder) => !currentTaggedFolders?.includes(folder.folderId))
      .map((folder) => ({
        text: folder.folderName,
        value: folder.folderName,
      }));
  };

  const handleDireactWebSearch = async () => {
    try {
      setChatApiCalling(true);
      const contentState = editorState.getCurrentContent();
      const hasText = contentState.getPlainText().trim();
      if (!hasText) {
        return toast.error("");
      }

      const response = await apiClient().post("/chat", {
        action: "webchat",
        query: hasText,
        createdAt: new Date().toISOString(),
      });
      // resetForm();
      // setIsOpenWebModal(false);
      const threadId = response?.data?.threadId;
      if (threadId) {
        navigate(`/answer-search?id=${threadId}`);
        await dispatch(getUserChatsWithinLimit(15));
        setChatApiCalling(false);
      }
      setChatApiCalling(false);
    } catch (error) {
      console.log("Error in Direact Web Search ", error);
      setChatApiCalling(false);
      toast.error(error);
    } finally {
      // setSubmitting(false);
      // setIsOpenWebModal(false);
      setChatApiCalling(false);
    }
  };

  const handleWebSearch = async () => {
    try {
      setChatApiCalling(true);
      const contentState = editorState.getCurrentContent();
      const hasText = contentState.getPlainText().trim();
      // if (!hasText) {
      //   return toast.error("");
      // }

      if (link.isLink === 0) {
        return toast.error("please enter a valid link");
      }
      const response = await apiClient().post("/chat", {
        action: "webchat",
        url: link.url,
        query: hasText,
        createdAt: new Date().toISOString(),
      });
      // resetForm();
      // setIsOpenWebModal(false);
      const threadId = response?.data?.threadId;
      if (threadId) {
        navigate(`/answer-search?id=${threadId}`);
        setChatApiCalling(false);
      }
      setChatApiCalling(false);
    } catch (error) {
      console.error("Error in API call:", error);
      // setIsOpenWebModal(false);
      setChatApiCalling(false);
      toast.error(error);
    } finally {
      // setSubmitting(false);
      // setIsOpenWebModal(false);
      setChatApiCalling(false);
    }
  };

  return (
    <>
      {(isLoading || status === "loading") && (
        <SpinnerLoading isLoading={true} />
      )}
      {userDetailsState && (
        <div
          className={`bg-white ${
            tab !== "chat" &&
            `${!isReplying && "xl:px-5"} lg:px-4 px-2 py-2 lg:py-4`
          }`}
        >
          {chatApiCalling && (
            <div className="flex justify-center items-center mb-24">
              <Icon
                icon="eos-icons:loading"
                width="2em"
                height="2em"
                className="text-blue-500"
              />
              <span className="ml-2">Processing your request...</span>
            </div>
          )}
          <div className={`relative border-2 rounded-lg `}>
            <div className="max-h-[300px] min-h-[100px] mb-2 relative">
              <FilesPreview
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                uploadingFiles={uploadingFiles}
              />
              {link.url && (
                <a
                  href={link.url}
                  target="_blank"
                  className="text-sm text-blue-700 p-2"
                >
                  {link?.url}
                </a>
              )}
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName  h-full"
                onEditorStateChange={(editorState) => {
                  handleChange(editorState);
                  handleMentionChange(editorState);
                }}
                handleReturn={handleReturn}
                mention={{
                  separator: " ",
                  trigger: "@",
                  suggestions: getSuggestions(),
                  handleReturn: (suggestion, mentionState) => {
                    if (suggestion) {
                      const newEditorState =
                        mentionState.addMention(suggestion);
                      handleChange(newEditorState);
                      return "handled";
                    }
                    return "not-handled";
                  },
                }}
                toolbar={{
                  options: ["inline", "list", "emoji", "image", "link"],
                  inline: {
                    options: ["bold", "italic"],
                    className: "inline-style",
                    bold: { icon: "/bold.svg", className: "bold-button" },
                  },
                  list: {
                    options: ["unordered", "ordered"],
                    className: "inline-style",
                  },
                  image: {
                    component: () => (
                      <ImageUpload
                        setSelectedFiles={setSelectedFiles}
                        setUploadingFiles={setUploadingFiles}
                      />
                    ),
                  },
                  link: {
                    component: () => (
                      <button
                        className="rdw-option-wrapper"
                        onClick={() => setIsWebChatOpen(true)}
                      >
                        <Icon
                          icon="cil:link"
                          style={{
                            color: link.isLink === 1 ? "blue" : "black",
                          }}
                          width="1.2em"
                          height="1.2em"
                        />
                      </button>
                    ),
                  },
                }}
              />
              {detectedLinks.length > 0 && (
                <LinkPreviewGrid
                  urls={detectedLinks}
                  setUrls={setDetectedLinks}
                  closedLinks={closedLinks}
                  setClosedLinks={setClosedLinks}
                />
              )}
            </div>
            <div className="flex justify-between items-end px-4 py-2 relative bottom-0 bg-white">
              <div className="flex items-center flex-wrap">
                {isOpen && (
                  <WebChatModel
                    isOpenWebModal={isOpen}
                    setIsOpenWebModal={setIsOpen}
                  />
                )}
                {isWebChatOpen && (
                  <EditorLinkModel
                    setIsOpenWebModal={setIsWebChatOpen}
                    setLink={setLink}
                    link={link}
                  />
                )}
              </div>
              <div className="flex-shrink-0">
                <button
                  className={`px-4 py-1 rounded-md ${
                    isAnotherspace
                      ? "text-borderColor cursor-not-allowed"
                      : (!editorState.getCurrentContent().hasText() &&
                          selectedFiles.length === 0) ||
                        isApiCalling
                      ? "text-borderColor cursor-not-allowed"
                      : "text-white"
                  }`}
                  style={{
                    backgroundColor: isApiCalling
                      ? "gray"
                      : editorState.getCurrentContent().hasText()
                      ? organization?.primaryColor
                      : organization?.secondaryColor,
                  }}
                  disabled={
                    isAnotherspace ||
                    (!editorState.getCurrentContent().hasText() &&
                      selectedFiles.length === 0) ||
                    isApiCalling
                  }
                  onClick={(e) => {
                    if (!isPlanActive) {
                      toast.error(
                        "Please upgrade your plan to use this feature"
                      );
                      return;
                    }
                    if (!isAnotherspace) {
                      if (link.isLink === 1) {
                        handleWebSearch();
                      } else {
                        handleReturn({ key: "Enter" });
                      }
                    }
                  }}
                >
                  {isAnotherspace ? (
                    <Icon icon="mdi:lock" width="1.5em" height="1.5em" />
                  ) : isApiCalling ? (
                    <Icon
                      icon="eos-icons:loading"
                      width="1.5em"
                      height="1.5em"
                    />
                  ) : (
                    <Icon
                      icon="mdi:paper-airplane"
                      width="1.5em"
                      height="1.5em"
                    />
                  )}
                </button>
                {organizationState &&
                  organizationState[0]?.isWebsearchUsed === true && (
                    <button
                      className={`px-4 py-1 rounded-md ${
                        isAnotherspace
                          ? "text-borderColor cursor-not-allowed"
                          : (!editorState.getCurrentContent().hasText() &&
                              selectedFiles.length === 0) ||
                            isApiCalling
                          ? "text-borderColor cursor-not-allowed"
                          : "text-white"
                      }`}
                      style={{
                        backgroundColor: isApiCalling
                          ? "gray"
                          : editorState.getCurrentContent().hasText()
                          ? organization?.primaryColor
                          : organization?.secondaryColor,
                        marginLeft: "10px",
                      }}
                      onClick={handleDireactWebSearch}
                    >
                      <Icon
                        icon="mdi:search-web"
                        width="1.5em"
                        height="1.5em"
                      />
                    </button>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default MessageEditor;

const EditorLinkModel = ({ setIsOpenWebModal, setLink, link }) => {
  const [url, setUrl] = useState("");
  const [validUrl, setValidUrl] = useState(false);
  const { organizationState } = useAppSelector((state) => ({
    organizationState: state.organization.organization,
  }));

  useEffect(() => {
    const regex = /^(https?):\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/.*)?$/;
    setValidUrl(regex.test(url));
  }, [url]);

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };
  const handleSubmit = (url) => {
    setLink({
      isLink: 1,
      url: url,
    });
  };

  return (
    <>
      <div className="fixed pl-64 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center">
        <div className="p-4 md:p-8 rounded-lg w-[350px] md:w-[600px] bg-white">
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-bold mb-4">Web chat</h2>
            <button
              onClick={() => setIsOpenWebModal(false)}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <Icon icon="iconamoon:close" width="1.5em" height="1.5em" />
            </button>
          </div>
          <div className="mb-4">
            <label
              htmlFor="url"
              className="block text-sm font-medium text-gray-700"
            >
              URL
            </label>
            <input
              type="url"
              id="url"
              name="url"
              defaultValue={link?.url || url}
              onChange={handleUrlChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            {/* Show error message if URL is invalid */}
            {url && !validUrl && (
              <div className="text-red-500 text-sm mt-1">
                Please enter a valid URL starting with http:// or https://
              </div>
            )}
          </div>
          <button
            type="button"
            onClick={() => {
              handleSubmit(url);
              setIsOpenWebModal(false);
            }}
            className="w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            style={{ backgroundColor: organizationState[0]?.primaryColor }}
            disabled={!validUrl}
          >
            Chat
          </button>
        </div>
      </div>
    </>
  );
};

import Footer from "../Components/HomePage/Footer";
import Header from "../Components/HomePage/Header";
import { contentArray, termsContent } from "../utils/static";

function TermsOfService() {
  return (
    <div className="overflow-x-hidden">
      <Header />
      <div className="w-full flex justify-center">
        <div className="Container bg-white">
          <div className="mt-10 mb-20 ">
            <div className="">
              <div className="flex items-center flex-col justify-center mb-8">
                <h1 className="text-2xl font-bold">TERMS OF SERVICE</h1>
                <p className="text-gray-500">Effective Date: June 1, 2024</p>
              </div>
              <div>
                {termsContent.map((paragraph, index) => (
                  <p key={index} className="mb-5 text-base font-normal">
                    {paragraph.text}
                  </p>
                ))}
              </div>

              <div>
                {contentArray.map((item, index) => (
                  <div key={index} className="mt-8">
                    <h1 className="text-2xl font-semibold">{item.title}</h1>
                    {item.description.map((paragraph, idx) => (
                      <p className="mt-5 text-base font-normal" key={idx}>
                        {paragraph.trim()}
                      </p>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TermsOfService;

import { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import { Icon } from "@iconify/react/dist/iconify.js";
import DashboardSidebar from "./DashboardSidebar";
import { useLocation } from "react-router-dom";
import AccountSetting from "../Components/Account/AccountSetting";
import OrganizationMembers from "../Components/Account/OrganizationMembers";
import useAuth from "../utils/useAuth";
import { useAppDispatch, useAppSelector } from "../store";
import { getUserDetails } from "../store/authSlice";
import { getOrganization } from "../store/organization";
import Aiseeting from "../Components/Account/AiSetting";
import OrganizationSettings from "../Components/Account/OrganizationSettings";
import Subscription from "../Components/Account/Subscription";

function AccountDetails() {
  const state = useLocation().state;
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState("Account");
  const { userDetailsState, organizationState } = useAppSelector((state) => ({
    userDetailsState: state.auth.userDetails,
    organizationState: state.organization.organization,
  }));

  useEffect(() => {
    if (user && !userDetailsState) {
      dispatch(getUserDetails(user?.userId));
    }
  }, [user]);

  useEffect(() => {
    if (organizationState?.length === 0) {
      dispatch(getOrganization());
    }
  }, []);

  useEffect(() => {
    if (state?.tab) {
      setActiveTab(state?.tab);
    }
  }, [state?.tab]);

  const org = organizationState[0];

  const isAdmin = org?.members?.find((member) => member?.userId === user?.userId)?.role !== "member";
  const member = org?.members?.find((member) => member?.userId === user?.userId);
  const isSuperAdmin = member?.role !== "admin" && member?.role !== "member";




  const tabs = [
    { icon: "ant-design:setting-outlined", name: "Account", disabled: false },
    { icon: "octicon:person-add-24", name: "Members", disabled: false },
    // { icon: "mdi:people", name: "Org Settings", disabled: !userDetailsState?.isRootAdmin },
    { icon: "mdi:people", name: "Org Settings", disabled: false },
    ...(userDetailsState?.isRootAdmin ? [{ icon: "fluent:wrench-settings-20-regular", name: "AI Settings", disabled: false }] : []),
    ...(isSuperAdmin ? [{ icon: "material-symbols:subscriptions", name: "Subscription", disabled: false }] : []),
  ];



  return (
    <>
      <div className={`w-full pt-10 px-[60px] header-height overflow-y-hidden`}>
        <div className="w-full">
          <div className="text-sm flex flex-col w-full font-medium text-secondary ">
            <p className="text-2xl font-normal text-textPrimary mb-5">User Settings</p>
            <ul className="flex gap-10 border-b-2 border-borderColor">
              {tabs.map((tab) => (
                <li key={tab.name} className="me-2 flex items-center">
                  <div
                    className={`flex items-center ${tab.disabled
                      ? "text-gray-400 cursor-not-allowed"
                      : activeTab === tab.name
                        ? " border-b-2 active "
                        : " hover:text-gray-600"
                      }`}
                    style={{
                      Color: tab.disabled
                        ? ""
                        : activeTab === tab.name
                          ? organizationState[0]?.primaryColor
                          : "",
                      borderColor: tab.disabled
                        ? ""
                        : activeTab === tab.name
                          ? organizationState[0]?.primaryColor : ""
                    }}

                  >
                    <Icon icon={tab.icon} width="1.2rem" height="1.2rem" style={{
                      color: activeTab === tab.name ? organizationState[0]?.primaryColor : 'gray',
                    }} />
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        if (!tab.disabled) setActiveTab(tab.name);
                      }}
                      className={`inline-block p-2 text-md font-medium rounded-t-lg `}
                      aria-current={
                        activeTab === tab.name ? "page" : undefined
                      }
                    >
                      {tab.name}
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="header-height overflow-y-hidden pt-4">
          <div className="header-height overflow-hidden">
            <div
              className="w-full overflow-y-auto"
              style={{ height: `calc(100vh - 188px)` }}
            >
              <div className={`w-full max-w-[700px] min-w-[700px] md:px-0 px-2 my-pb-20`}>
                <div className="pr-4 w-full">
                  <div className="flex flex-col w-full pb-20">
                    {activeTab === "Account" && <AccountSetting isAdmin={isAdmin} userDetailsState={userDetailsState} org={organizationState[0]} user={user} />}
                    {activeTab === "Members" && <OrganizationMembers isAdmin={isAdmin} userDetailsState={userDetailsState} org={organizationState[0]} user={user} />}
                    {activeTab === "AI Settings" && <Aiseeting isAdmin={isAdmin} userDetailsState={userDetailsState} org={organizationState[0]} user={user} />}
                    {activeTab === "Org Settings" && <OrganizationSettings isAdmin={isAdmin} userDetailsState={userDetailsState} org={organizationState[0]} user={user} />}
                    {activeTab === "Subscription" && <Subscription isAdmin={isAdmin} userDetailsState={userDetailsState} org={organizationState[0]} user={user} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div>
            </div> */}
      </div>
    </>
  );
}

export default AccountDetails;
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { setAuthState, setUserDetails } from "../store/authSlice";
import { clearAllStates } from "../store/finChat";
import { useSocket } from "../context/SocketProvider";
import { useAppDispatch, useAppSelector } from "../store";

const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { authState } = useAppSelector((state) => ({
    authState: state.auth.authState,
  }));
  const [user, setUser] = useState(null);
  const { socket } = useSocket();

  const handleLogOut = () => {
    localStorage.removeItem("authToken");
    dispatch(setAuthState(false));
    dispatch(setUserDetails(null));
    dispatch(clearAllStates());
    navigate("/");
    socket.disconnect();
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const token = localStorage.getItem("authToken");
      if (!token) {
        navigate("/");
      } else {
        const decodedToken = jwtDecode(token);
        setUser(decodedToken);
        dispatch(setAuthState(true));
      }
    }
  }, [dispatch, navigate, authState]);

  return { authState, user, handleLogOut };
};

export default useAuth;

import React, { useEffect, useState, useRef, useCallback } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import Sidebar from "../Components/Sidebar";
import { useAppDispatch, useAppSelector } from "../store";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import apiClient from "../utils/axiosInstance";
import {
  addChat,
  addEmojiinSpace,
  getThreadChats,
  removeChat,
  resetChat,
  updateMessage,
  updateSpaceMessageCounter,
} from "../store/ThreadSlice";
import useAuth from "../utils/useAuth";
import { ContentState, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Members from "../Components/Team/Members";
import InviteModal from "../Components/sidebar/InviteModal";
import { useSocket } from "../context/SocketProvider";
import { v4 as uuidv4 } from "uuid";
import { getOrganization } from "../store/organization";
import {
  MessageList,
  EmptyState,
  MessageSkeleton,
} from "../Components/SharedMessageComponents";
import { getTeams } from "../store/teamSlice";
import MessageEditor from "../Components/MessageEditor";
import { toast } from "react-toastify";
import ReplyMessages from "../Components/ReplyMessages";
import { jwtDecode } from "jwt-decode";

function Team() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const teamId = queryParams.get("id");
  const token = queryParams.get("token");

  const { user } = useAuth();
  const { socket } = useSocket();
  const messagesEndRef = useRef(null);
  const { threadChatState, teamState, organizationState, userDetailsState } =
    useAppSelector((state) => ({
      teamState: state.teams.teams,
      threadChatState: state.thread.threadChats,
      organizationState: state.organization.organization,
      userDetailsState: state.auth.userDetails,
    }));
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [isApiCalling, setIsApiCalling] = useState(false);
  const [inviteModule, setInviteModule] = useState(false);
  const [isLoadingChats, setIsLoadingChats] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [sharedFolders, setSharedFolders] = useState([]);
  const [isAnotherspace, setIsAnotherspace] = useState(false);
  const [isLoadingTeamData, setIsLoadingTeamData] = useState(false);
  const [team, setTeam] = useState(null);
  const [mentionState, setMentionState] = useState({
    showAskFin: true,
    askFinTagged: false,
  });
  const [taggedFolders, setTaggedFolders] = useState([]);
  const [isReplying, setIsReplying] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(teamId);
  // const [error, setError] = useState(null);
  useEffect(() => {
    setSelectedTeam(teamId);
  },[teamId])
  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  // useEffect(() => {
  //   if (token) {
  //     const decoded = jwtDecode(token);
  //     if (!decoded.teamId || !decoded.email || !decoded.teamName) {
  //       setError("Invalid token. Please try again.");
  //       setIsLoadingChats(false);
  //       return;
  //     }
  //     const updateUserStatus = async () => {
  //       try {
  //         setIsLoadingChats(true);
  //         await apiClient().put(
  //           `/accept-team-invite/${decoded.email}/${decoded.teamId}`
  //         );
  //         setIsLoadingChats(false);
  //       } catch (err) {
  //         setError("Failed to update user status. Please try again.");
  //         setIsLoadingChats(false);
  //       }
  //     };

  //     updateUserStatus();
  //   }
  // }, [token]);
  useEffect(() => {
    scrollToBottom();
  }, [threadChatState, scrollToBottom]);

  useEffect(() => {
    const fetchSharedFolders = async () => {
      if (teamId) {
        try {
          const response = await apiClient().post(`/get-shared-folders`, {
            sharedTo: [teamId],
            isForDm: false,
          });
          setSharedFolders(response.data.folders);
        } catch (error) {
          console.error("Error fetching shared folders:", error);
        }
      }
    };
    fetchSharedFolders();
  }, [teamId]);

  useEffect(() => {
    if (organizationState.length === 0) {
      dispatch(getOrganization());
    }
  }, []);

  useEffect(() => {
    if (teamId) {
      const fetchTeamById = async () => {
        setIsLoadingTeamData(true);
        try {
          const response = await apiClient().get(`/team/${teamId}`);
          setTeam(response.data.team);
        } catch (error) {
          console.log("Error fetching team:", error);
        } finally {
          setIsLoadingTeamData(false);
        }
      };

      if (teamState.length > 0) {
        const findTeam = teamState.find((team) => team.teamId === teamId);
        if (!findTeam) {
          setIsAnotherspace(true);
          fetchTeamById();
        } else {
          setTeam(findTeam);
        }
      }
    }
  }, [teamId, teamState]);

  useEffect(() => {
    if (teamState.length === 0) {
      dispatch(getTeams());
    }
  }, []);

  useEffect(() => {
    if (teamId) {
      setIsLoadingChats(true);
      dispatch(getThreadChats({ threadId: teamId }))
        .then(() => setIsLoadingChats(false))
        .catch(() => setIsLoadingChats(false));
    } else {
      setIsLoadingChats(false);
    }
    return () => dispatch(resetChat());
  }, [teamId, dispatch]);

  useEffect(() => {
    if (!user || !socket) return;

    socket.emit("join_space_room", {
      room: `space-room-${teamId}`,
      userId: user.userId,
    });

    const handleNewMessage = ({ message }) => {
      dispatch(addChat(message));
      scrollToBottom();
      setEditorState(EditorState.createEmpty());
      setIsApiCalling(false);
    };

    socket.on("receive_space_message", handleNewMessage);
    socket.on("space_reply_message", ({ message }) => {
      if (isReplying === false) {
        const data = {
          messageId: message.messageId,
          profile: message.profile,
        };
        dispatch(updateSpaceMessageCounter(data));
      }
    });
    socket.on("emoji_thread_reply", (data) => {
      dispatch(
        addEmojiinSpace({
          messageId: data.message_data.messageId,
          emojis: data.message_data.emoji,
        })
      );
    });

    return () => {
      socket.emit("leave_space_room", { room: `space-room-${teamId}` });
      socket.off("receive_space_message", handleNewMessage);
      socket.off("space_reply_message");
      socket.off("emoji_thread_reply");
    };
  }, [teamId, user, socket, dispatch, scrollToBottom, isReplying]);

  const handleSendMessage = async (editorState) => {
    let message = "";
    const hasText = editorState.getCurrentContent().hasText();
    const hasFiles = selectedFiles.length > 0;

    if ((!hasText && !hasFiles) || uploadingFiles.length > 0) return;

    setIsApiCalling(true);
    message = editorState.getCurrentContent().getPlainText();

    const baseMessageData = {
      threadId: teamId,
      messageId: uuidv4(),
      isThread: false,
      tag: [{ accountType: "team", tagId: teamId }],
      createdAt: new Date().toISOString(),
      senderId: user?.userId,
      senderName: user?.name,
      senderProfile: userDetailsState?.userPictureUrl,
    };

    let messageData;

    if (hasText && hasFiles) {
      messageData = {
        ...baseMessageData,
        message: message,
        messageType: "text-file",
        files: selectedFiles.map((file) => ({
          docS3Loc: file.docS3Loc,
          url: file.url,
          fileName: file.file.name,
        })),
      };
    } else if (hasText) {
      messageData = {
        ...baseMessageData,
        message: message,
        messageType: "text",
      };
    } else if (hasFiles) {
      messageData = {
        ...baseMessageData,
        messageType: "file",
        files: selectedFiles.map((file) => ({
          docS3Loc: file.docS3Loc,
          url: file.url,
          fileName: file.file.name,
        })),
      };
    }

    await sendMessage(messageData);
    setIsApiCalling(false);
  };

  const sendMessage = async (messageData) => {
    let messageId = uuidv4();
    const data = {
      room: `space-room-${teamId}`,
      message: messageData,
      teamId: teamId,
      teamName: team?.teamName,
    };

    socket.emit("new_space_message", data);
    if (
      mentionState.askFinTagged ||
      messageData.message.includes(`@${organizationState[0]?.chatBotName}`)
    ) {
      dispatch(addChat(messageData));
      const tempAiData = {
        createdAt: new Date().toISOString(),
        answer: {
          answer: `${organizationState[0]?.chatBotName} is thinking...`,
        },
        messageId: messageId,
        isAiChat: true,
        messageType: "text",
        receivers: messageData.tag,
        senderId: messageData.senderId,
        senderName: messageData.senderName,
        threadId: teamId,
        tag: messageData.tag,
      };
      dispatch(addChat(tempAiData));
    } else {
      dispatch(addChat(messageData));
    }
    setEditorState(EditorState.createEmpty());
    setSelectedFiles([]);
    scrollToBottom();

    try {
      const apiMessageData = {
        threadId: messageData.threadId,
        messageId: messageData.messageId,
        isThread: false,
        tag: messageData.tag,
        createdAt: messageData.createdAt,
        messageType: messageData.messageType,
      };

      if (
        messageData.messageType === "text" ||
        messageData.messageType === "text-file"
      ) {
        apiMessageData.message = messageData.message;
      }

      if (
        messageData.messageType === "file" ||
        messageData.messageType === "text-file"
      ) {
        apiMessageData.files = messageData.files;
      }

      await apiClient().post("/thread", apiMessageData);
      if (
        mentionState.askFinTagged ||
        messageData.message.includes(`@${organizationState[0]?.chatBotName}`)
      ) {
        if (taggedFolders.length > 0) {
          try {
            const payload = {
              threadId: teamId,
              folderIds: taggedFolders,
              query: messageData.message,
              messageId: messageId,
              createdAt: new Date().toISOString(),
              senderId: messageData.senderId,
              receivers: messageData.tag,
              type: "thread",
            };
            const response = await apiClient().post("/chatwithfolder", payload);
            const answer = {
              answers: response.data.response,
            };
            dispatch(updateMessage({ messageId: messageId, answer: answer }));
          } catch (error) {
            dispatch(removeChat(messageId));
            toast.error(error);
          }
        } else {
          try {
            let body = {
              messageId: messageId,
              query: messageData.message
                .replace(`@${organizationState[0]?.chatBotName}`, "")
                .trim(),
              action: "chat",
              createdAt: new Date().toISOString(),
              threadId: teamId,
            };

            const response = await apiClient().post(`/chat`, body);
            dispatch(
              updateMessage({
                messageId: messageId,
                answer: response.data.answer,
              })
            );
          } catch (error) {
            dispatch(removeChat(messageId));
            toast.error(error);
          }
        }
      }
    } catch (error) {
      console.error("Error posting message:", error);
    }
  };

  const handleRelatedQueryClick = (query) => {
    setMentionState({ ...mentionState, askFinTagged: true });
    const prefixedQuery = `@${organizationState[0]?.chatBotName} ${query}`;
    const newEditorState = EditorState.createWithContent(
      ContentState.createFromText(prefixedQuery)
    );
    handleSendMessage(newEditorState);
  };
  const navigate = useNavigate();
  return (
    <>
      {teamState.length === 0 ? (
        <>
          <div className="flex flex-col items-center justify-center w-full header-height text-gray-500">
            <Icon icon="carbon:chat" width="100" height="100" />
            <h2 className="mt-4 text-xl font-semibold">
              No Conversations Yet!
            </h2>
          </div>
        </>
      ) : (
        <>
          <div
            className="flex justify-between px-2 header-height"
            style={{ width: "calc(100vw - 366px)" }}
          >
            <div
              className={`flex flex-col ${
                isReplying ? "md:w-[58%]" : "w-full"
              } my-pb-20 header-height py-1`}
            >
              <div
                className={`border-b-2 ${
                  !isReplying && "xl:px-5"
                } h-[70px] rounded transition-all duration-300 ease-in-out flex items-center justify-between`}
              >
                <div className="close-btn md:hidden">
                  <button>
                    <Icon
                      icon="carbon:close"
                      className="w-6 h-6 text-textPrimary"
                      onClick={() => navigate("/dashboard")}
                    />
                  </button>
                </div>
                <h1 className="flex items-center text-xl font-semibold">
                  <Icon
                    icon="tdesign:member"
                    width="1.2em"
                    height="1.2em"
                    className="mr-1"
                  />
                  {team?.teamName || "Loading..."}
                </h1>
                <div className="md:block flex items-center justify-between">
                  {isAnotherspace ? (
                    isLoadingTeamData ? (
                      <div className="flex items-center">
                        {[...Array(3)].map((_, index) => (
                          <div key={index} className="-ml-2 first:ml-0">
                            <div className="w-8 h-8 rounded-full bg-gray-300 animate-pulse"></div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      team && (
                        <Members
                          teamIds={[teamId]}
                          setInviteModule={setInviteModule}
                          isAnotherspace={isAnotherspace}
                          setIsAnotherspace={setIsAnotherspace}
                          team={team}
                          setSelectedTeam={setSelectedTeam}
                        />
                      )
                    )
                  ) : (
                    <Members
                      teamIds={[teamId]}
                      setInviteModule={setInviteModule}
                      isAnotherspace={isAnotherspace}
                      team={team}
                      setSelectedTeam={setSelectedTeam}
                    />
                  )}
                </div>
              </div>
              <div className="flex-1 flex flex-col overflow-hidden bg-white">
                <div className="flex-1 overflow-y-auto px-0 lg:px-4 ">
                  {isLoadingChats ? (
                    <>
                      <MessageSkeleton />
                      <MessageSkeleton />
                      <MessageSkeleton />
                    </>
                  ) : threadChatState.length > 0 ? (
                    <MessageList
                      messages={threadChatState}
                      handleRelatedQueryClick={handleRelatedQueryClick}
                      isReplying={isReplying}
                      setIsReplying={setIsReplying}
                      tab="team"
                      organization={organizationState[0]}
                    />
                  ) : (
                    <EmptyState />
                  )}
                  <div ref={messagesEndRef} />
                </div>
                <MessageEditor
                  isAnotherspace={isAnotherspace}
                  organization={organizationState[0]}
                  handleSendMessage={handleSendMessage}
                  isApiCalling={isApiCalling}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  uploadingFiles={uploadingFiles}
                  setUploadingFiles={setUploadingFiles}
                  mentionState={mentionState}
                  setMentionState={setMentionState}
                  taggedFolders={taggedFolders}
                  setTaggedFolders={setTaggedFolders}
                  editorState={editorState}
                  setEditorState={setEditorState}
                  sharedFolders={sharedFolders}
                  isReplying={isReplying}
                />
              </div>
            </div>
            {isReplying && (
              <ReplyMessages
                isReplying={isReplying}
                setIsReplying={setIsReplying}
                sharedFolders={sharedFolders}
                user={user}
                socket={socket}
                tab="team"
                id={teamId}
                notification={team?.teamName}
                organization={organizationState[0]}
              />
            )}
          </div>
          <InviteModal
            inviteModule={inviteModule}
            setInviteModule={setInviteModule}
            selectedTeam={selectedTeam}
          />
        </>
      )}
    </>
  );
}

export default Team;

import ChatAnswer from "../Components/ChatAnswer";
import { useEffect, useRef, useState } from "react";
import SpinnerLoading from "../Components/SpinnerLoading";
import Sidebar from "../Components/Sidebar";
import { useLocation } from "react-router-dom";
import ChatLoader from "../Components/ChatLoader";
import ChatBox from "../Components/ChatBox";
import { useAppDispatch, useAppSelector } from "../store";
import { clearChatQueries, getChatsByChatId } from "../store/finChat";
import useAuth from "../utils/useAuth";
import DashboardSidebar from "./DashboardSidebar";
import {
  EmptyState,
  MessageSkeleton,
} from "../Components/SharedMessageComponents";
import { Icon } from "@iconify/react/dist/iconify.js";
import apiClient from "../utils/axiosInstance";
import { updateLimitedChat } from "../store/finChat";

function AnswerSearch({ closeSideBar }) {
  const { user } = useAuth();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const lastNodeRef = useRef();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const { chatWithLimitState, chatQueries, status } = useAppSelector(
    (state) => ({
      chatWithLimitState: state.finChat.chatWithLimit,
      chatQueries: state?.finChat?.chatQueries,
      status: state?.finChat?.chatStatus,
    })
  );
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState("");
  const [isEditTitle, setIsEditTitle] = useState(false);

  const [title, setTitle] = useState("");

  const questions = chatQueries?.chat?.queries
    ?.slice(-5)
    ?.map((answer) => answer.query);

  useEffect(() => {
    const getQueries = async () => {
      dispatch(getChatsByChatId(id));
    };
    if (user && id) {
      getQueries();
    }
  }, [user, id]);

  useEffect(() => {
    setTitle(chatQueries?.chat?.chatName);
  }, [chatQueries]);

  useEffect(() => {
    return () => {
      dispatch(clearChatQueries());
    };
  }, []);

  useEffect(() => {
    if (lastNodeRef.current) {
      lastNodeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatQueries, question]);

  const handleEditChatName = async () => {
    if (isEditTitle) {
      const body = {
        newName: title,
      };
      dispatch(
        updateLimitedChat({
          threadId: chatQueries?.chat?.threadId,
          chatName: title,
        })
      );
      setIsEditTitle(false);
      const { data } = await apiClient().put(
        `/thread/name-update/${chatQueries?.chat?.threadId}`,
        body
      );
    } else {
      setIsEditTitle(true);
    }
  };

  return (
    <>
      {status === "loading" || isLoading ? (
        <div className="flex-1 ">
          <MessageSkeleton />
          <MessageSkeleton />
          <MessageSkeleton />
        </div>
      ) : chatWithLimitState.chats === 0 ? (
        <>
          <div className="flex flex-col items-center justify-center w-full header-height text-gray-500">
            <Icon icon="carbon:chat" width="100" height="100" />
            <h2 className="mt-4 text-xl font-semibold">No Conversation Yet!</h2>
          </div>
        </>
      ) : (
        <div
          className={`bg-backgroundColor header-height flex justify-center items-center w-full`}
        >
          <div className="rounded-lg sm:w-full md:w-full lg:w-full">
            <div className="relative">
              <div className="header-height overflow-y-hidden lg:h-[100%] xl:h-[100vh] w-full">
                <div className={`grid grid-cols-1 gap-4`}>
                  <div
                    className={`col-span-1 lg:col-span-3 border-r-2 w-full border-borderColor header-height overflow-y-scroll flex flex-col justify-between`}
                  >
                    <div
                      className={`${
                        !chatQueries?.chat?.queries.length > 0
                          ? "header-height"
                          : ""
                      }`}
                    >
                      <div
                        style={{
                          height: "70px",
                          margin: "4px 16px 24px 16px",
                          borderBottom: "2px solid #E5E7EB",
                        }}
                        className="flex items-center text-[20px]"
                      >
                        <Icon
                          icon={!isEditTitle ? "tdesign:edit" : "tdesign:check"}
                          width="0.8em"
                          height="0.8em"
                          className="ml-1 mr-2 cursor-pointer"
                          onClick={handleEditChatName}
                        />
                        {!isEditTitle && title}
                        {isEditTitle && (
                          <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        )}
                      </div>

                      {chatQueries?.chat?.queries?.map((item, index, array) => {
                        const isLastIndex = index === array.length - 1;

                        return (
                          <div key={item?.messageId}>
                            <ChatAnswer
                              messageId={item?.messageId}
                              setIsChatLoading={setIsChatLoading}
                              setIsLoading={setIsLoading}
                              answerChat={item}
                              threadId={id}
                              setQuestion={setQuestion}
                              isLastIndex={isLastIndex}
                              closeSideBar={closeSideBar}
                              index={index}
                            />
                          </div>
                        );
                      })}
                    </div>
                    {question && (
                      <div className="px-4 py-2 break-words mt-5 mb-8">
                        <p className="text-lg font-normal text-textPrimary">
                          {question}
                        </p>
                      </div>
                    )}
                    {isChatLoading && <ChatLoader />}
                    <div ref={lastNodeRef}></div>
                    <ChatBox
                      setIsChatLoading={setIsChatLoading}
                      threadId={id}
                      setQuestion={setQuestion}
                      questions={questions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AnswerSearch;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import store from "./store";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { ConfigProvider } from "react-avatar";

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  person_profiles: "identified_only",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
    <Provider store={store}>
      <BrowserRouter>
        <PostHogProvider client={posthog}>
          <ConfigProvider colors={["#F5F5F7", "#F5F5F7"]}>
            <App />
          </ConfigProvider>
        </PostHogProvider>
      </BrowserRouter>
    </Provider>
  </GoogleOAuthProvider>
);

reportWebVitals();

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import apiClient from "../../utils/axiosInstance";
import {
  deleteUserLimitedChat,
  starredThread,
  updateChatNotification,
} from "../../store/finChat";
import { Icon } from "@iconify/react/dist/iconify.js";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import Modal from "../Modal";
import { IoAdd } from "react-icons/io5";

function SidebarRenderThreads({ isItemActive, setActiveItemId }) {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { chatWithLimitState, organizationState } = useAppSelector((state) => ({
    chatWithLimitState: state.finChat.chatWithLimit,
    organizationState: state.organization.organization,
  }));
  const [showButtons, setShowButtons] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [discardedThreadId, setDiscardedThreadId] = useState(null);

  const handleItemClick = (itemId) => {
    setActiveItemId(itemId);
  };

  useEffect(() => {
    if (
      /*  location?.state?.isFirstTime && */
      chatWithLimitState?.history?.length > 0
    ) {
      const thread = chatWithLimitState?.history[0];
      setActiveItemId(thread.threadId);
      if (thread.type === "chat") {
        navigate(`/answer-search?id=${thread.threadId}`);
      } else {
        navigate(`/thread?id=${thread.threadId}`);
      }
    }
  }, [location?.state || chatWithLimitState?.history]);

  const resetThreadChatsNotification = async (threadId) => {
    try {
      await apiClient().put(`/reset-notification/${threadId}`);
      dispatch(updateChatNotification({ threadId: threadId, notification: 0 }));
    } catch (error) {
      console.error("Error resetting notification:", error);
    }
  };

  const handleStarredThread = async (threadId) => {
    try {
      dispatch(starredThread(threadId));
      await apiClient().put(`/thread-starred-discarded/${threadId}`, {
        field: "starred",
      });
    } catch (error) {
      console.error("Error starring thread:", error);
    }
  };

  const discardedThread = async () => {
    try {
      await apiClient().put(`/thread-starred-discarded/${discardedThreadId}`, {
        field: "discarded",
      });
      dispatch(deleteUserLimitedChat(discardedThreadId));
    } catch (error) {
      console.error("Error discarding thread:", error);
    }
  };

  const handleDiscarded = (threadId) => {
    setShowModal(true);
    setDiscardedThreadId(threadId);
  };

  const confirmDiscardedThread = () => {
    discardedThread();
    setShowModal(false);
    setShowButtons(false);
  };

  return (
    <ul className="space-y-2 px-3 py-1 ">
      <li>
        <div className="flex items-center justify-between py-5 border-b-2">
          <p className="text-xl font-bold">Threads</p>
          <button onClick={() => navigate("/dashboard")}>
            <Icon
              icon="heroicons:pencil-square"
              width="1.3em"
              height="1.3em"
              className="text-secondary"
            />
          </button>
        </div>
      </li>
      {chatWithLimitState?.history?.slice(0, 10).map((item, index) => (
        <li
          key={index}
          className={`text-sm flex  items-center p-1 rounded-lg transition-colors duration-200 hover:bg-slate-200`}
          style={{
            background: isItemActive(item.threadId)
              ? organizationState[0]?.secondaryColor
              : "",
            color: isItemActive(item.threadId) ? "white" : "black",
          }}
        >
          <div
            className="relative group"
            onMouseEnter={() => setShowButtons(index)}
            onMouseLeave={() => setShowButtons(false)}
          >
            <div className="flex items-center">
              <Icon
                icon="heroicons:chat-bubble-left-ellipsis"
                width="1.5em"
                height="1.5em"
                className="text-pricingText mr-2"
                style={{
                  color: isItemActive(item.threadId) ? "white" : "black",
                }}
              />
              <Link
                to={
                  item.type === "chat"
                    ? `/answer-search?id=${item.threadId}`
                    : `/thread?id=${item?.threadId}`
                }
                className={`truncate ${item.notification > 0 ? "w-48" : "w-56"
                  } flex-grow  capitalize  font-normal ${item.notification > 0 && "text-gray-900 font-semibold"
                  }`}
                onClick={(e) => {
                  handleItemClick(item.threadId);
                  if (item.notification > 0) {
                    e.preventDefault();
                    resetThreadChatsNotification(item.threadId).then(() => {
                      const path =
                        item.type === "chat"
                          ? `/answer-search?id=${item.threadId}`
                          : `/thread?id=${item?.threadId}`;
                      navigate(path);
                    });
                  }
                }}
                title={item.chatName}
              >
                {item.chatName}
              </Link>
            </div>
            {showButtons === index && (
              <div
                className={`absolute right-0 top-[-4px] flex space-x-2 rounded-md px-1 py-1 transition-opacity bg-white duration-200 ${showButtons
                  ? "opacity-100"
                  : "opacity-0 group-hover:opacity-100"
                  }`}
              >
                <p className="text-sm font-normal text-gray-600">
                  {format(new Date(item.createdAt), "d MMM")}
                </p>
                <button
                  className="rounded"
                  style={{
                    color: organizationState[0]?.secondaryColor,
                  }}
                  onClick={() => {
                    handleStarredThread(item.threadId);
                  }}
                >
                  {item.starred ? (
                    <Icon icon="mdi:star" width="1.5em" height="1.5em" />
                  ) : (
                    <Icon
                      icon="mdi:star-outline"
                      width="1.5em"
                      height="1.5em"
                    />
                  )}
                </button>
                <button
                  className="rounded"
                  style={{
                    color: organizationState[0]?.secondaryColor,
                  }}
                  onClick={() => handleDiscarded(item.threadId)}
                >
                  <Icon
                    icon="gravity-ui:bell-slash"
                    width="1.2em"
                    height="1.2em"
                  />
                </button>
              </div>
            )}
          </div>

          {item.notification > 0 && (
            <span className="bg-red-500 text-white text-xs font-bold w-5 h-5 p-[1.5px] rounded-full ml-2 text-center">
              {item.notification}
            </span>
          )}
        </li>
      ))}
      {chatWithLimitState?.history?.length > 0 && (
        <button
          className=" font-normal  text-center ml-2 border-b-2 "
          style={{
            color: organizationState[0]?.primaryColor,
            borderColor: organizationState[0]?.primaryColor,
          }}
          onClick={() => navigate("/chat-history")}
        >
          View All
        </button>
      )}
      <div className="flex items-center gap-2 py-1 rounded hover:bg-slate-200">
      <div className="flex items-center justify-center p-2 rounded-md" style={{backgroundColor:"#e5e7eb"}}><IoAdd/></div>
        <button
          className="text-center text-sm"
          onClick={() => {
            navigate(`/dashboard`);
          }}
        >
          Create new Thread
        </button>
      </div>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        confirmAction={confirmDiscardedThread}
        label={"Discard Thread"}
        description={
          "Are you sure you want to discard this thread? This action cannot be undone and the thread will be permanently removed from your account."
        }
        confirmText={"Confirm"}
        cancelText={"Cancel"}
      />
    </ul>
  );
}

export default SidebarRenderThreads;

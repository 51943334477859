import React, { useEffect, useState } from "react";
import apiClient from "../utils/axiosInstance";
import { useAppSelector } from "../store";

function Preview({ imageUrl, hoveredIndex = 0, index = 0 }) {

  const domain = new URL(imageUrl).origin;

  return (
    <>
      <div className="w-4 h-4 flex items-ceter mx-2">
        <img
          src={`${domain}/favicon.ico`}
          alt="favicon"

          className="w-full self-start"
        />
      </div>
    </>
  );
}

export default Preview;

import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../store";
import useAuth from "../utils/useAuth";
import { useDispatch } from "react-redux";
import { getOrganization } from "../store/organization";
import MessageEditor from "./MessageEditor";
import { ContentState, EditorState, Modifier } from "draft-js";
import apiClient from "../utils/axiosInstance";

function Chat({ SetDashboard }) {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const [dropdownItems, setDropdownItems] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [tags, setTags] = useState([]);
  const [latestTaggedItemId, setLatestTaggedItemId] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [mentionState, setMentionState] = useState({
    showAskFin: true,
    askFinTagged: false,
  });
  const [taggedFolders, setTaggedFolders] = useState([]);
  const [sharedFolders, setSharedFolders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chatApiCalling, setChatApiCalling] = useState(false);
  const [isApiCalling, setIsApiCalling] = useState(false);
  const { organizationState } = useAppSelector((state) => ({
    organizationState: state.organization.organization,
  }));
  const [editorState, setEditorState] = useState(() => {
    const initialContent = ContentState.createFromText(
      `@${organizationState[0]?.chatBotName} `
    );
    let initialState = EditorState.createWithContent(initialContent);

    const selection = initialState.getSelection();
    const contentState = initialState.getCurrentContent();
    const mentionEntity = contentState.createEntity("MENTION", "IMMUTABLE", {
      mention: { name: organizationState[0]?.chatBotName },
    });
    const entityKey = mentionEntity.getLastCreatedEntityKey();

    const mentionText = `@${organizationState[0]?.chatBotName}`;
    const contentStateWithEntity = Modifier.applyEntity(
      contentState,
      selection.merge({
        anchorOffset: 0,
        focusOffset: mentionText.length,
      }),
      entityKey
    );
    initialState = EditorState.push(
      initialState,
      contentStateWithEntity,
      "apply-entity"
    );
    return EditorState.moveFocusToEnd(initialState);
  });

  useEffect(() => {
    const fetchSharedFolders = async () => {
      if (latestTaggedItemId) {
        try {
          const response = await apiClient().post(`/get-shared-folders`, {
            sharedTo: [latestTaggedItemId],
            isForDm: false,
          });
          setSharedFolders((prev) => [...prev, ...response.data.folders]);
        } catch (error) {
          console.error("Error fetching shared folders:", error);
        }
      }
    };

    fetchSharedFolders();
  }, [latestTaggedItemId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
        setInputValue("");
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchOrganization = async () => {
      setIsLoading(true);

      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await dispatch(getOrganization()).unwrap();
      } catch (error) {
        console.error("Error fetching organization:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!organizationState?.length > 0) {
      fetchOrganization();
    }
  }, []);

  const isTeamTagged = (teamId) => {
    return (
      tags?.some((tag) => tag.type === "team" && tag.id === teamId) || false
    );
  };

  const isMemberTagged = (memberId) => {
    return (
      tags?.some((tag) => tag.type === "member" && tag.id === memberId) || false
    );
  };

  const getMembersFromTaggedTeams = () => {
    if (!tags || tags.length === 0) {
      return [];
    }
    const taggedTeamIds = tags
      .filter((tag) => tag.type === "team")
      .map((tag) => tag.id);
    return (
      organizationState[0]?.teams
        ?.filter((team) => taggedTeamIds.includes(team.teamId))
        .flatMap((team) => team.members.map((member) => member.email)) || []
    );
  };

  const getAllActiveMembers = () => {
    return organizationState[0]?.members
      .filter((member) => member.isAccepted)
      .filter((member) => member.userId !== user?.userId)
      .map((member) => ({
        id: member.email,
        type: "member",
        name: member.name,
        userId: member.userId,
      }));
  };

  const handleToChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    const lastAtIndex = value.lastIndexOf("@");
    const lastHashIndex = value.lastIndexOf("#");

    if (lastAtIndex !== -1 && lastAtIndex > lastHashIndex) {
      const query = value.slice(lastAtIndex + 1);
      const taggedTeamMembers = getMembersFromTaggedTeams();
      const allActiveMembers = getAllActiveMembers();

      const filteredMembers = allActiveMembers.filter(
        (member) =>
          member.name.toLowerCase().includes(query.toLowerCase()) &&
          !isMemberTagged(member.id) &&
          !taggedTeamMembers.includes(member.id)
      );
      setDropdownItems(filteredMembers);
      setShowDropdown(filteredMembers.length > 0);
    } else if (lastHashIndex !== -1) {
      const query = value.slice(lastHashIndex + 1);
      const teams =
        organizationState[0]?.teams.map((team) => ({
          id: team.teamId,
          type: "team",
          name: team.teamName,
        })) || [];
      const filteredTeams = teams.filter(
        (team) =>
          team.name.toLowerCase().includes(query.toLowerCase()) &&
          !isTeamTagged(team.id)
      );
      setDropdownItems(filteredTeams);
      setShowDropdown(filteredTeams.length > 0);
    } else {
      setShowDropdown(false);
    }
  };

  const handleItemClick = (item) => {
    const newTag = {
      id: item.id,
      type: item.type,
      name: item.name,
      prefix: item.type === "team" ? "#" : "@",
      removable: true,
      userId: item?.userId,
    };
    if (item.type === "team") {
      const taggedTeamMembers =
        organizationState[0]?.teams
          ?.filter((team) => team.teamId === item.id)
          .flatMap((team) => team.members.map((member) => member.email)) || [];
      setTags((prevTags) => {
        const updatedTags = prevTags.filter(
          (tag) => !taggedTeamMembers.includes(tag.id)
        );
        return [...updatedTags, newTag];
      });
      setLatestTaggedItemId(item.id);
    } else {
      setTags((prevTags) => {
        return [...(prevTags || []), newTag];
      });
      setLatestTaggedItemId(item.userId);
    }
    setInputValue("");
    setShowDropdown(false);
  };

  const removeTag = (index) => {
    const tagToRemove = tags[index];
    setTags((prevTags) => {
      const newTags = prevTags.filter((_, i) => i !== index);
      return newTags;
    });

    if (tagToRemove.type === "team") {
      setSharedFolders((prevSharedFolders) =>
        prevSharedFolders.filter((folder) => folder.sharedTo !== tagToRemove.id)
      );
    } else {
      setSharedFolders((prevTaggedFolders) =>
        prevTaggedFolders.filter(
          (folder) => folder.sharedTo !== tagToRemove.userId
        )
      );
    }
  };

  return (
    <>
      <div className="dashboard-chat flex items-center justify-center bg-backgroundColor header-height xxl:px-80 xl:px-40 h-full">
        <div
          className={`bg-white rounded-lg border border-borderColor flex flex-col w-full`}
        >
          <div className="p-4 border-b-2 border-gray-200 flex items-center justify-between">
            <h1 className="text-2xl font-semibold tracking-wider">
              New Thread
            </h1>
            <div className="close-btn md:hidden">
              <button>
                <Icon
                  icon="carbon:close"
                  className="w-6 h-6 text-textPrimary"
                  onClick={() => {
                    SetDashboard(false);
                  }}
                />
              </button>
            </div>
          </div>
          <div className="flex items-center p-4 border-b-2 border-gray-200">
            <p className="flex font-medium">
              To <span className="mx-2">:</span>
            </p>
            <div className="flex flex-wrap items-center gap-2 w-full">
              {tags
                ?.filter((tag) => tag.type === "team" || tag.type === "member")
                .map((tag, index) => (
                  <span
                    key={index}
                    className={`px-3 py-1 rounded-full flex items-center text-sm ${
                      tag.type === "team"
                        ? "bg-green-100 text-green-800"
                        : "bg-purple-100 text-purple-800"
                    }`}
                  >
                    {tag.prefix}
                    {tag.name}
                    <button
                      onClick={() => removeTag(index)}
                      className="ml-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                    >
                      <Icon icon="mdi:close" className="w-4 h-4" />
                    </button>
                  </span>
                ))}
              <input
                value={inputValue}
                onChange={handleToChange}
                className="flex-grow min-w-[200px] border-none focus:outline-none focus:ring-0 text-sm"
                placeholder={!tags?.length > 0 && "#spaces @member"}
              />
            </div>
          </div>

          {showDropdown && dropdownItems.length > 0 && (
            <div
              ref={dropdownRef}
              className="absolute z-10 bg-white shadow-lg max-h-60 rounded-lg overflow-auto mt-2 mx-4 border border-gray-200 w-[calc(800px-2rem)]"
            >
              {dropdownItems.map((item, index) => (
                <div
                  key={index}
                  onClick={() => handleItemClick(item)}
                  className="cursor-pointer select-none relative py-2 px-3 hover:bg-gray-100"
                >
                  <span className="font-normal truncate flex items-center">
                    <Icon
                      icon={
                        item.type === "team"
                          ? "fluent:people-team-16-regular"
                          : "carbon:user-multiple"
                      }
                      className={`mr-2 ${
                        item.type === "team"
                          ? "text-green-500"
                          : "text-purple-500"
                      }`}
                    />
                    {item.name}
                  </span>
                </div>
              ))}
            </div>
          )}

          <div className="flex-grow p-4 flex flex-col justify-end">
            <MessageEditor
              selectedFiles={selectedFiles}
              organization={organizationState[0]}
              setSelectedFiles={setSelectedFiles}
              uploadingFiles={uploadingFiles}
              setUploadingFiles={setUploadingFiles}
              tags={tags}
              setTags={setTags}
              mentionState={mentionState}
              setMentionState={setMentionState}
              taggedFolders={taggedFolders}
              setTaggedFolders={setTaggedFolders}
              editorState={editorState}
              setEditorState={setEditorState}
              chatApiCalling={chatApiCalling}
              setChatApiCalling={setChatApiCalling}
              isApiCalling={isApiCalling}
              setIsApiCalling={setIsApiCalling}
              sharedFolders={sharedFolders}
              isLoading={isLoading}
              tab="chat"
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Chat;

import Sidebar from "../Components/Sidebar";
import History from "../Components/ChatHistory/history";
import DashboardSidebar from "./DashboardSidebar";
import { useAppSelector } from "../store";

function ChatHistory() {
  const { userDetailsState, organizationState } = useAppSelector((state) => ({
    userDetailsState: state.auth.userDetails,
    organizationState: state.organization.organization
  }))
  return (
    <>
      <div className="bg-backgroundColor  header-height flex justify-center items-center md:px-0 px-2 my-pb-20 w-full">
        <div className=" rounded-lg sm:w-full md:w-full lg:w-full">
          <div className="relative">
            <div className="header-height overflow-y-hidden lg:h-[100%] xl:h-[100vh] w-full">
              <History />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatHistory;